import Spinner from "components/Spinner";
import TabNav from "components/tabNav";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { useGetUserList } from "utils/api/user.api";
import { type AccountLevelData } from "utils/types/user.type";

export const accountUpgradeLinks = [
  { to: "/account-level-upgrade", label: "Users" },
  { to: "/kyc", label: "KYC" },
  { to: "/kyc-history", label: "History" },
];

export default function AccountLevelUpgrade() {
  const [toShow, setToShow] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading, refetch } = useGetUserList(
    undefined,
    toShow,
    searchValue
  );
  useEffect(() => {
    void refetch();
  }, [toShow]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      void refetch();
    }, 1200);
    return () => clearTimeout(timeOut);
  }, [searchValue]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={accountUpgradeLinks} />

          <div className="overflow-auto mt-4">
            <div className="table-wrapper max-h-[48rem] overflow-auto">
              <form action="#" className="flex items-center gap-6 mb-4">
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by date, email address or transaction type"
                  className="w-full text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-3.5 px-5"
                />
                <button className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3">
                  Search
                </button>
              </form>
              <table className="w-full">
                <tbody>
                  <tr className="px-2 bg-cyan-700 text-white  ">
                    <td className="rounded-l-lg  px-5 py-5">
                      <p className="font-bold text-xs">Time</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Name</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Email</p>
                    </td>

                    <td className="py-5 px-2 rounded-r-lg w-[100px]">
                      <p className="font-bold text-xs ">Level</p>
                    </td>
                  </tr>
                  {isLoading ? (
                    <Spinner />
                  ) : data?.length === 0 ? (
                    "No Records to show"
                  ) : (
                    data?.map((data) => <TableRow key={data?.id} data={data} />)
                  )}
                </tbody>
              </table>
              {data && data?.length > 49 && data?.length === toShow && (
                <div className="flex justify-center">
                  <button
                    className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                    onClick={() => setToShow(toShow + 50)}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ data }: { data: AccountLevelData }) {
  return (
    <>
      <tr className={"bg-cyan-300"}>
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(data?.updatedAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {data?.name || data?.fullname}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.email}</p>
        </td>

        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          <p className="text-black-900 text-xs ">Level {data?.kycTier || 1}</p>
        </td>
      </tr>
    </>
  );
}
