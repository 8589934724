import React from "react";
import { NavLink, useLocation } from "react-router-dom";

interface TabNavProps {
  navLinks: Array<{ to: string; label: string }>;
}

const TabNav: React.FC<TabNavProps> = ({ navLinks }) => {
  const location = useLocation();

  return (
    <ul className="flex flex-wrap items-center gap-3 mb-4 text-xl">
      {navLinks.map((link: any, index: number) => (
        <li key={index}>
          <NavLink
            to={link.to}
            className={
              location.pathname === link.to
                ? "text-black-900"
                : "text-black-900/20"
            }
          >
            {link.label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default TabNav;
