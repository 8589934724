import Spinner from "components/Spinner";
import React from "react";
import toast from "react-hot-toast";
import { AiOutlineArrowLeft } from "react-icons/ai";

const ExportPopup = ({
  isExportPopupVisible,
  setExportPopupVisible,
  exportOption,
  setExportOption,
  isFromAnalytics,
  onClickAll,
  setFromDate,
  setToDate,
  fromDate,
  toDate,
  load,
  noDates,
}: any) => {
  return (
    isExportPopupVisible && (
      <div
        className={`h-[100vh] w-[100vw] fixed inset-0 bg-[rgba(0,0,0,${
          !isFromAnalytics ? 0.6 : 0.3
        })] z-[1112]`}
      >
        <div className="fixed px-8 py-8 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white p-5 border-2 border-gray-200 rounded-md shadow-lg z-[1113]">
          {exportOption !== "All" && (
            <button
              className="absolute top-2 left-2 text-black-700 text-lg cursor-pointer flex items-center"
              onClick={() => setExportOption("All")}
            >
              <AiOutlineArrowLeft className="mt-1 mr-2" />
            </button>
          )}
          <button
            className="absolute top-2 right-2 text-black-700 text-lg cursor-pointer"
            onClick={() => {
              setExportPopupVisible(false);
              setExportOption("All");
              if (fromDate) {
                setFromDate("");
                setToDate("");
              }
            }}
          >
            &#10005; {/* Unicode character for 'X' */}
          </button>
          {exportOption === "All" && (
            <div className="flex flex-col gap-2 mt-8">
              <button
                className="hover:bg-cyan-800 w-80 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                onClick={() => {
                  if (load) {
                    return;
                  }
                  setExportPopupVisible(false);
                  setExportOption("All");
                  onClickAll();
                  if (fromDate) {
                    setFromDate("");
                    setToDate("");
                  }
                }}
              >
                Export Whole Data
              </button>
              {noDates ? (
                <></>
              ) : (
                <button
                  className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                  onClick={() => setExportOption("ByDate")}
                >
                  Export by Dates
                </button>
              )}
            </div>
          )}
          {exportOption === "ByDate" && (
            <div className="flex flex-col justify-center mt-4 px-4">
              {/* Add input fields for From and To dates here */}

              <label htmlFor="from" className="mr-2 mb-2 w-80">
                From Date
              </label>
              <input
                id="from"
                type="date"
                className="text-black-700 text-sm mb-6 rounded-lg border border-black-800 focus:outline-0 py-1.5 px-2"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />

              <label htmlFor="to" className="mr-2 mb-2">
                To Date
              </label>
              <input
                id="to"
                type="date"
                className="text-black-700 text-sm mb-6 rounded-lg border border-black-800 focus:outline-0 py-1.5 px-2"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <button
                disabled={load}
                className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                onClick={() => {
                  if (fromDate === "" || toDate === "") {
                    toast.error("Please select both dates");
                    return;
                  }
                  setExportPopupVisible(false);
                  setExportOption("All");
                  onClickAll();
                  setFromDate("");
                  setToDate("");
                }}
              >
                {load ? <Spinner /> : "Export"}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default ExportPopup;
