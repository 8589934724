import ChartComponent from "components/chart";
import ExportPopup from "components/exportPopUp";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import {
  useCsvGetUserActivities,
  useGetUserActivities,
} from "utils/api/auth.api";
import {
  useCsvCoins,
  useCsvGetCoinsMarketData,
  useCsvGetTradingHistory,
  useGetCoins,
  useGetCoinsHistoryOneYear,
  useGetCoinsMarketData,
  useGetDepositWithdrawAnalysis,
  useGetTradingHistory,
} from "utils/api/user.api";
import {
  type TradingHistory,
  type MarketData,
  type CoinType,
  type UserActivity,
} from "utils/types/user.type";

export default function Analytics() {
  const [toShow, setToShow] = useState(50);
  const { data: coins } = useGetCoins();
  const { data: coinChartData } = useGetCoinsHistoryOneYear();
  const { data: marketData } = useGetCoinsMarketData();
  const {
    data: userActivities,
    isLoading: activityLoading,
    refetch,
  } = useGetUserActivities(toShow, 0, "");
  const { data: tradingHistoryData } = useGetTradingHistory();
  const { data: depositWithdraw } = useGetDepositWithdrawAnalysis();
  console.log(depositWithdraw);
  const [isExportPopupVisibleCrypto, setExportPopupVisibleCrypto] =
    useState(false);
  const [exportOption, setExportOption] = useState("All");
  const [
    isExportPopupVisibleCryptoTrading,
    setExportPopupVisibleCryptoTrading,
  ] = useState(false);
  const [exportOptionTrading, setExportOptionTrading] = useState("All");
  const [isExportPopupVisibleCryptoUser, setExportPopupVisibleCryptoUser] =
    useState(false);
  const [exportOptionUser, setExportOptionUser] = useState("All");
  const [isExportPopupVisibleCryptoMarket, setExportPopupVisibleCryptoMarket] =
    useState(false);
  const [exportOptionMarket, setExportOptionMarket] = useState("All");
  const [isMonth, setIsMonth] = useState(true);

  const monthCategories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const monthSeries = [
  //   {
  //     name: "Withdrawal Amount",
  //     data: [30, 40, 25, 50, 49, 21, 70, 91, 56],
  //   },
  //   {
  //     name: "Deposit Amount",
  //     data: [40, 50, 15, 60, 69, 31, 80, 101, 66],
  //   },
  // ];

  const weekCategories = ["Week 1", "Week 2", "Week 3", "Week 4"];

  // const weekSeries = [
  //   {
  //     name: "Withdrawal Amount",
  //     data: [50, 60, 45, 70],
  //   },
  //   {
  //     name: "Deposit Amount",
  //     data: [60, 70, 25, 90],
  //   },
  // ];

  const transformData = (data: any[] | any[], isMonth: boolean): any => {
    const categories = isMonth ? monthCategories : weekCategories;
    const depositAmounts = data?.map((entry) =>
      isMonth ? entry?.totalTransaction : entry?.totalAmountInUsd
    );
    const withdrawAmounts = data?.map((entry) =>
      isMonth ? entry?.totalTransaction : entry?.totalAmountInUsd
    );
    const depositCount = data?.map((entry) => entry?.count);
    const withdrawCount = data?.map((entry) => entry?.count);

    return {
      categories,
      chartSeries: [
        {
          name: "Withdrawal Amount",
          data: withdrawAmounts,
        },
        {
          name: "Deposit Amount",
          data: depositAmounts,
        },
        {
          name: "Withdrawal Count",
          data: withdrawCount,
        },
        {
          name: "Deposit Count",
          data: depositCount,
        },
      ],
    };
  };

  const depositChartData = transformData(
    isMonth
      ? depositWithdraw?.deposit?.monthlyData
      : depositWithdraw?.deposit?.weeklyData,
    isMonth
  );

  // Transform withdrawal data
  const withdrawalChartData = transformData(
    isMonth
      ? depositWithdraw?.withdraw?.monthlyData
      : depositWithdraw?.withdraw?.weeklyData,
    isMonth
  );
  console.log(depositWithdraw);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDateMarket, setFromDateMarket] = useState("");
  const [toDateMarket, setToDateMarket] = useState("");
  const [fromDateUser, setFromDateUser] = useState("");
  const [toDateUser, setToDateUser] = useState("");
  const [fromDateTrading, setFromDateTrading] = useState("");
  const [toDateTrading, setToDateTrading] = useState("");
  const {
    data: csvData,
    refetch: refetchCoinsCsv,
    isLoading: csvLoadingCoin,
  }: any = useCsvCoins(fromDate, toDate);
  const {
    data: csvDataTrading,
    refetch: refetchTradingCsv,
    isLoading: csvLoadingTrading,
  }: any = useCsvGetTradingHistory(fromDateTrading, toDateTrading);
  const {
    data: csvDataMarket,
    refetch: refetchMarketCsv,
    isLoading: csvLoadingMarket,
  }: any = useCsvGetCoinsMarketData(fromDateMarket, toDateMarket);
  const {
    data: csvDataUser,
    refetch: refetchUserCsv,
    isLoading: csvLoadingUser,
  }: any = useCsvGetUserActivities(
    Number.MAX_SAFE_INTEGER,
    0,
    fromDateUser,
    toDateUser
  );

  const onClickAll = () => {
    const blob = new Blob([csvData], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "coins.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onClickMarketDataExport = () => {
    const blob = new Blob([csvDataMarket], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "market_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onClickUserActivitiesExport = () => {
    const blob = new Blob([csvDataUser], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "user_activities.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onClickTradingHistoryExport = () => {
    const blob = new Blob([csvDataTrading], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "trading_history.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    void refetchCoinsCsv();
  }, [fromDate, toDate]);
  useEffect(() => {
    void refetchMarketCsv();
  }, [fromDateMarket, toDateMarket]);
  useEffect(() => {
    void refetchTradingCsv();
  }, [fromDateTrading, toDateTrading]);
  useEffect(() => {
    void refetchUserCsv();
  }, [fromDateUser, toDateUser]);

  useEffect(() => {
    void refetch();
  }, [toShow]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <h2 className="text-black font-bold text-lg md:text-xl ">
            Analytics and Reporting
          </h2>
          <div className="overflow-auto mt-5">
            <div className="flex justify-end mb-2">
              <button
                className={`w-[80px] rounded-l-xl h-8 bg-${
                  isMonth ? "green-400" : "cyan-300"
                }`}
                onClick={() => setIsMonth(true)}
              >
                Month
              </button>
              <button
                className={`w-[80px] rounded-r-xl h-8 bg-${
                  !isMonth ? "green-400" : "cyan-300"
                }`}
                onClick={() => setIsMonth(false)}
              >
                Week
              </button>
            </div>

            <ChartComponent
              categories={isMonth ? monthCategories : weekCategories}
              chartSeries={[
                depositChartData.chartSeries[1],
                withdrawalChartData.chartSeries[0],
                depositChartData.chartSeries[3],
                withdrawalChartData.chartSeries[2],
              ]}
              naira={true}
            />
            <ExportPopup
              isExportPopupVisible={isExportPopupVisibleCryptoMarket}
              setExportPopupVisible={setExportPopupVisibleCryptoMarket}
              exportOption={exportOptionMarket}
              setExportOption={setExportOptionMarket}
              onClickAll={onClickMarketDataExport}
              setFromDate={setFromDateMarket}
              fromDate={fromDateMarket}
              toDate={toDateMarket}
              setToDate={setToDateMarket}
              isFromAnalytics="true"
              load={csvLoadingMarket}
            />
            <div className="flex items-center justify-between mt-[30px]">
              <h2 className="text-black font-bold text-xl ">Market Data</h2>
              <button
                onClick={() => setExportPopupVisibleCryptoMarket(true)}
                className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
              >
                Export
              </button>
            </div>
            <div className="w-[976px]">
              <div className=" max-h-[48rem] overflow-auto">
                <table className="w-full table-wrapper">
                  <tbody>
                    <tr className="px-2 bg-cyan-700 text-white font-bold">
                      <td className="rounded-l-lg  px-5 py-5">
                        <p className=" text-xs w-[30%]">Date</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">Name</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">Open</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">Close</p>
                      </td>
                      <td className="py-5 px-2 rounded-r-lg w-[100px]">
                        <p className=" text-xs ">Volume</p>
                      </td>
                    </tr>
                    {marketData?.map((marketData, idx) => (
                      <TableRow key={idx} marketData={marketData} />
                    ))}
                  </tbody>
                </table>
              </div>
              <ExportPopup
                isExportPopupVisible={isExportPopupVisibleCryptoUser}
                setExportPopupVisible={setExportPopupVisibleCryptoUser}
                exportOption={exportOptionUser}
                setExportOption={setExportOptionUser}
                onClickAll={onClickUserActivitiesExport}
                setFromDate={setFromDateUser}
                fromDate={fromDateUser}
                toDate={toDateUser}
                setToDate={setToDateUser}
                isFromAnalytics="true"
                load={csvLoadingUser}
              />
              <div className="flex items-center justify-between mt-[30px]">
                <h2 className="text-black font-bold text-xl ">
                  User Activities
                </h2>
                <button
                  onClick={() => setExportPopupVisibleCryptoUser(true)}
                  className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
                >
                  Export
                </button>
              </div>
              <div className=" max-h-[48rem] overflow-auto">
                <table className="w-full table-wrapper">
                  <tbody>
                    <tr className="px-2 bg-cyan-700 text-white font-bold">
                      <td className="rounded-l-lg  px-5 py-5">
                        <p className=" text-xs w-[25%]">Date</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">Type</p>
                      </td>
                      <td className="py-5 px-2 w-[40%]">
                        <p className="text-xs ">Description</p>
                      </td>
                      <td className="py-5 px-2 rounded-r-lg w-[100px]">
                        <p className="text-xs ">User</p>
                      </td>
                    </tr>
                    {userActivities?.map((activity, idx) => (
                      <TableRow2 key={idx} activity={activity} />
                    ))}
                  </tbody>
                </table>
                {userActivities &&
                  userActivities?.length > 49 &&
                  userActivities?.length === toShow && (
                    <div className="flex justify-center">
                      <button
                        className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                        onClick={() => setToShow(toShow + 50)}
                        disabled={activityLoading}
                      >
                        {activityLoading ? "Loading..." : "Load More"}
                      </button>
                    </div>
                  )}
              </div>
              <ExportPopup
                isExportPopupVisible={isExportPopupVisibleCryptoTrading}
                setExportPopupVisible={setExportPopupVisibleCryptoTrading}
                exportOption={exportOptionTrading}
                setExportOption={setExportOptionTrading}
                onClickAll={onClickTradingHistoryExport}
                setFromDate={setFromDateTrading}
                fromDate={fromDateTrading}
                toDate={toDateTrading}
                setToDate={setToDateTrading}
                isFromAnalytics="true"
                load={csvLoadingTrading}
              />
              <div className="flex items-center justify-between mt-[30px]">
                <h2 className="text-black font-bold text-xl ">
                  Trading History
                </h2>
                <button
                  onClick={() => setExportPopupVisibleCryptoTrading(true)}
                  className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
                >
                  Export
                </button>
              </div>
              <div className=" max-h-[48rem] overflow-auto">
                <table className="w-full table-wrapper">
                  <tbody>
                    <tr className="px-2 bg-cyan-700 text-white font-bold">
                      <td className="rounded-l-lg  px-5 py-5 w-[20%]">
                        <p className=" text-xs">Date</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">User</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">Deposit</p>
                      </td>
                      <td className="py-5 px-2 w-[20%]">
                        <p className=" text-xs ">Withdrawal</p>
                      </td>
                      <td className="py-5 px-2 rounded-r-lg w-[6%]">
                        <p className=" text-xs ">Swap</p>
                      </td>
                    </tr>
                    {tradingHistoryData?.map((trade, idx) => (
                      <TableRow3 key={idx} transaction={trade} />
                    ))}
                  </tbody>
                </table>
              </div>

              <ExportPopup
                isExportPopupVisible={isExportPopupVisibleCrypto}
                setExportPopupVisible={setExportPopupVisibleCrypto}
                exportOption={exportOption}
                setExportOption={setExportOption}
                onClickAll={onClickAll}
                setFromDate={setFromDate}
                fromDate={fromDate}
                toDate={toDate}
                setToDate={setToDate}
                isFromAnalytics="true"
                load={csvLoadingCoin}
              />
              <div className="flex items-center justify-between mt-[30px]">
                <h2 className="text-black font-bold text-xl ">
                  Crypto Reserves
                </h2>
                <button
                  onClick={() => setExportPopupVisibleCrypto(true)}
                  className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
                >
                  Export
                </button>
              </div>
              <div className=" max-h-[48rem] overflow-auto">
                <table className="w-full table-wrapper">
                  <tbody>
                    {coins?.map((coin, idx) => (
                      <TableRow4 key={idx} coin={coin} data={coinChartData} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ marketData }: { marketData: MarketData }) {
  return (
    <>
      <tr className={`px-2 bg-cyan-300 `}>
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(marketData?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{marketData?.coinId}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(marketData?.open)?.toLocaleString() || 0}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(marketData?.close)?.toLocaleString() || 0}
          </p>
        </td>
        <td className="py-5 px-2 rounded-r-lg w-[100px]">
          <p className="text-black-900 text-xs ">
            {Number(marketData?.totalVolume)?.toLocaleString()}
          </p>
        </td>
      </tr>
    </>
  );
}

function TableRow2({ activity }: { activity: UserActivity }) {
  return (
    <>
      <tr className={`px-2 bg-cyan-300 `}>
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(activity?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{activity?.type}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{activity?.details}</p>
        </td>
        <td className="py-5 px-2 rounded-r-lg ">
          <p className="text-black-900 text-xs ">{activity?.userName}</p>
        </td>
      </tr>
    </>
  );
}

function TableRow3({ transaction }: { transaction: TradingHistory }) {
  return (
    <>
      <tr className={`px-2 bg-cyan-300 `}>
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(transaction?.date).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{transaction?.userName}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(
              (transaction?.deposit || [])
                .reduce((acc, cVal) => Number(acc) + Number(cVal), 0)
                ?.toFixed(2)
            )?.toLocaleString() || 0}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(
              (transaction?.withdraw || [])
                .reduce((acc, cVal) => Number(acc) + Number(cVal), 0)
                ?.toFixed(2)
            )?.toLocaleString() || 0}
          </p>
        </td>
        <td className="py-5 px-2 rounded-r-lg ">
          <p className="text-black-900 text-xs ">
            {Number(
              (transaction?.swap || [])
                .reduce((acc, cVal) => Number(acc) + Number(cVal), 0)
                ?.toFixed(2)
            )?.toLocaleString() || 0}
          </p>
        </td>
      </tr>
    </>
  );
}

function TableRow4({ coin, data }: { coin: CoinType; data: any }) {
  const [isMonth, setIsMonth] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState("");
  console.log(selectedCoin);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const monthCategories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekCategories = ["Week 1", "Week 2", "Week 3", "Week 4"];

  const transformedData = data?.map((entry: any) => {
    const coinName = entry?.coinName;

    const monthlyData = entry?.monthlyData?.map((dat: any) => dat?.price) || [];
    const weeklyData = entry?.weeklyData?.map((dat: any) => dat?.price) || [];

    return {
      coinName,
      monthCategories,
      weekCategories,
      monthSeriesCoin: [
        {
          name: "Monthly Price",
          data: monthlyData,
        },
      ],
      weekSeriesCoin: [
        {
          name: "Weekly Price",
          data: weeklyData,
        },
      ],
    };
  });

  // console.log(transformedData)
  const filteredData = transformedData?.filter((dat: any) => {
    return (
      dat?.coinName?.toLowerCase() ===
      selectedCoin?.split(" ")[0]?.toLowerCase()
    );
  });

  console.log(filteredData);

  return (
    <>
      <tr className={`px-2 bg-cyan-300 `}>
        <td className="rounded-l-lg  px-5 py-5">
          <div className="flex items-center gap-3">
            <img src={coin?.icon} alt="icon" width={42} height={42} />
            <p className="text-black-900 text-lg font-md">
              {coin?.symbol?.includes("USDT")
                ? "USDT"
                : coin?.symbol?.includes("USDC")
                ? "USDC"
                : coin?.symbol}
            </p>
          </div>
        </td>
        <td className="py-5 px-2 rounded-r-lg w-[100px] pr-5">
          <div className="flex items-center justify-end gap-3">
            <p className="text-lg font-md">
              {Number(coin?.priceMarket)?.toLocaleString()}
            </p>
            <i
              className="fa-regular fa-eye text-red hover:text-[16px]"
              style={{ cursor: "pointer", color: "" }}
              onClick={() => {
                toggle();
                setSelectedCoin(coin?.name);
              }}
            ></i>
          </div>
        </td>
        {isOpen && (
          <div
            id="authentication-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed top-0 right-0 left-0 z-[20000] flex justify-center items-center w-full h-full bg-black bg-opacity-50"
          >
            <div className="relative p-4  overflow-auto">
              <div className="relative md:w-[70vw] lg:w-[50vw] w-[90vw] bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Coin Price Data
                  </h3>
                  <button
                    type="button"
                    className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={toggle}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5">
                  <div className="flex justify-end mb-2">
                    <button
                      className={`w-[80px] rounded-l-xl h-8 bg-${
                        isMonth ? "green-400" : "cyan-300"
                      }`}
                      onClick={() => setIsMonth(true)}
                    >
                      Month
                    </button>
                    <button
                      className={`w-[80px] rounded-r-xl h-8 bg-${
                        !isMonth ? "green-400" : "cyan-300"
                      }`}
                      onClick={() => setIsMonth(false)}
                    >
                      Week
                    </button>
                  </div>
                  <ChartComponent
                    categories={
                      isMonth
                        ? filteredData[0]?.monthCategories
                        : filteredData[0]?.weekCategories
                    }
                    chartSeries={
                      isMonth
                        ? filteredData[0]?.monthSeriesCoin
                        : filteredData[0]?.weekSeriesCoin
                    }
                    w={"100%"}
                    yTitle={"Coin Price (USD)"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </tr>
    </>
  );
}
