/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint @typescript-eslint/restrict-template-expressions: off */
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import {
  type UserTransaction,
  type CoinType,
  type UserStats,
  type ListedUser,
  type User,
  type UpdateUserEmail,
  type UpdateUserPassword,
  type Airtime,
  type InternetD,
  type ElectricityD,
  type TVSubs,
  type MarketData,
} from "utils/types/user.type";

export const useGetLoggedInUser = () =>
  useQuery<User, Error>(
    ["getLoggedInUser"],
    async () => await client(`auth/getLoggedInUser`)
  );

export const useGetTransactions = (
  status: "Completed" | "Pending" | "Rejected",
  type?: "Deposit" | "Swap" | "Withdraw" | "Withdraw Fiat" | "Deposit Fiat",
  toShow?: number
) =>
  useQuery<UserTransaction[], Error>(
    ["getTransactions", status, type],
    async () =>
      await client(
        `wallet/getTransactionsByAdmin${
          toShow ? `?limit=${toShow}` : `?limit={50}`
        }&offset=0&status=${status}${type ? `&type=${type}` : ""}`
      )
  );

export const useCsvTransactionsByAdmin = (
  status: "Completed" | "Pending" | "Rejected",
  type?: "Deposit" | "Swap" | "Withdraw" | "Withdraw Fiat",
  toShow?: number,
  fromDate?: string,
  toDate?: string
) =>
  useQuery<UserTransaction[], Error>(
    ["CsvTransactionsByAdmin", status, type, toShow, fromDate, toDate],
    async () => {
      const queryString = `wallet/CsvTransactionsByAdmin?limit=${
        Number.MAX_SAFE_INTEGER
      }&offset=0&status=${status}${
        fromDate ? `&fromDate=${fromDate.toString()}` : ""
      }${toDate ? `&toDate=${toDate.toString()}` : ""}${
        type ? `&type=${type}` : ""
      }`;

      return await client(queryString);
    }
  );

export const useGetStats = () =>
  useQuery<UserStats, Error>(
    ["getStats"],
    async () => await client(`wallet/getStats`)
  );

export const useGetUserList = (
  isAdmin?: boolean,
  toShow?: number,
  email?: string
) =>
  useQuery<ListedUser[], Error>(
    ["getUserList"],
    async () =>
      await client(
        `auth/getAllUsers${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }${isAdmin ? "&filterOnlyAdmins=true" : ""}${
          email ? `&email=${email}` : ""
        }`
      )
  );

export const useCsvUsers = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>(["CsvUsers"], async () => {
    const queryString = `auth/CsvUsers?limit=${
      Number.MAX_SAFE_INTEGER
    }&offset=${0}${fromDate ? `&fromDate=${fromDate.toString()}` : ""}${
      toDate ? `&toDate=${toDate.toString()}` : ""
    }`;

    return await client(queryString);
  });

export const useGetRecentTransactions = (limit?: number, offset?: number) =>
  useQuery<any, Error>(["getRecentTransactions", limit, offset], async () => {
    const queryString = `wallet/getRecentTransactions?limit=${
      limit ?? Number.MAX_SAFE_INTEGER
    }&offset=${offset ?? 0}`;
    return await client(queryString);
  });

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      userId: string;
      isActive: boolean;
      phone: string;
      fullname: string;
      email: string;
      kycTier?: string;
      bvn?: string;
    }
  >(async (data) => await client(`auth/updateUserActive`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useGetCoins = () =>
  useQuery<CoinType[], Error>(
    ["getCoins"],
    async () => await client(`coins/getCoins`)
  );

export const useGetCoinsMarketData = () =>
  useQuery<MarketData[], Error>(
    ["getCoinsMarketData"],
    async () => await client(`coins/getCoinsMarketData`)
  );

export const useCsvGetCoinsMarketData = (fromDate?: string, toDate?: string) =>
  useQuery<MarketData[], Error>(
    ["CsvGetCoinsMarketData", fromDate, toDate],
    async () =>
      await client(
        `coins/CsvGetCoinsMarketData${fromDate ? `?fromDate=${fromDate}` : ""}${
          toDate ? `${fromDate ? "&" : "?"}toDate=${toDate}` : ""
        }`
      )
  );

export const useGetDepositWithdrawAnalysis = () =>
  useQuery<any, Error>(
    ["getDepositWithdrawAnalysis"],
    async () => await client(`coins/getDepositWithdrawAnalysis`)
  );

export const useUpdateCoinPrice = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { price?: number; priceSell?: number; coinId: string }
  >(async (data) => await client(`coins/updatePrice`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getCoins"]);
    },
  });
};

export const useUpdateEmail = () =>
  useMutation<unknown, Error, UpdateUserEmail>(
    async (data) => await client(`auth/changeEmail`, { data })
  );

export const useUpdatePassword = () =>
  useMutation<unknown, Error, UpdateUserPassword>(
    async (data) => await client(`auth/changePassword`, { data })
  );

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { userId: string; role: "User" | "Admin" | string }
  >(async (data) => await client(`auth/updateUserRole`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useGetUserWalletBalance = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>(
    async (id) => await client(`wallet/getUserWalletWithBalance/${id}`),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getUserList"]);
      },
    }
  );
};

// export const useUpdateTransaction = () => {
//   const queryClient = useQueryClient();
//   return useMutation<unknown, Error, { transactionId: string; status: string }>(
//     async (data) => await client(`wallet/updateTransaction`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getTransactions", "Pending"]);
//       },
//     }
//   );
// };

export const useCsvCoins = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>(["CsvCoins"], async () => {
    const queryString = `coins/CsvCoins?limit=${900}&offset=${0}${
      fromDate ? `&fromDate=${fromDate.toString()}` : ""
    }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

    return await client(queryString);
  });

export const useVerifyAndUpgradeKYC3ByAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    { UserId: string; kycTier: string; status: string }
  >(
    async (data) => await client(`auth/verifyAndUpgradeKYC3ByAdmin`, { data }),
    {
      onSuccess() {
        // Invalidate relevant queries or perform other actions on success
        void queryClient.invalidateQueries(["getKyc3UsersVerifyRecords"]);
      },
    }
  );
};

export const useGetKycHistory = (status: string, email: string) =>
  useQuery<any[], Error>(
    ["getKycHistory", status],
    async () =>
      await client(`auth/getKycHistory?status=${status}&email=${email}`)
  );

export const useGetKyc3UsersVerifyRecords = (email: string) =>
  useQuery<ListedUser[], Error>(
    ["getKyc3UsersVerifyRecords"],
    async () => await client(`auth/getKyc3UsersVerifyRecords?email=${email}`)
  );

export const useEnable2Fa = () =>
  useQuery<any, Error>(
    ["enable2FA"],
    async () => await client(`auth/enable2FA`)
  );

export const useEnable2FaOtp = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>(
    async (otp: string) =>
      await client(`auth/enable2FA/${otp}`, { method: "POST" }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getLoggedInUser"]);
      },
    }
  );
};

// export const useEnable2FaOtp = (otp: string) =>
//   useQuery<unknown, Error>(
//     ["enable2Fa", otp],
//     async () => await client(`auth/enable2Fa/${otp}`)
//   );

export const useGetCompletedAirtime = (toShow: number) =>
  useQuery<Airtime[], Error>(
    ["getCompletedAirtime", toShow],
    async () =>
      await client(
        `service/getCompletedAirtime${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      )
  );

export const useGetCompletedInternet = (toShow: number) =>
  useQuery<InternetD[], Error>(
    ["getCompletedInternet", toShow],
    async () =>
      await client(
        `service/getCompletedInternet${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      )
  );

export const useGetCompletedElectricityBill = (toShow: number) =>
  useQuery<ElectricityD[], Error>(
    ["getCompletedElectricityBill", toShow],
    async () =>
      await client(
        `service/getCompletedElectricityBill${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      )
  );

export const useGetCompletedvSubscription = (toShow: number) =>
  useQuery<TVSubs[], Error>(
    ["getCompeletedvSubscription", toShow],
    async () =>
      await client(
        `service/getCompeletedtvSubscription${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      )
  );

export const useGetCoinsHistoryOneYear = () =>
  useQuery<any[], Error>(
    ["getCoinsHistoryOneYear"],
    async () => await client(`coins/getCoinsHistoryOneYear`)
  );

export const useGetTradingHistory = () =>
  useQuery<any[], Error>(
    ["getTradingHistory"],
    async () => await client(`coins/getTradingHistory`)
  );

export const useCsvGetTradingHistory = (fromDate?: string, toDate?: string) =>
  useQuery<any[], Error>(
    ["CsvGetTradingHistory", fromDate, toDate],
    async () =>
      await client(
        `coins/CsvGetTradingHistory${fromDate ? `?fromDate=${fromDate}` : ""}${
          toDate ? `${fromDate ? "&" : "?"}toDate=${toDate}` : ""
        }`
      )
  );

export const useUploadFile = () => {
  return useMutation<unknown, Error, any>(async (data: any) => {
    console.log(data);
    return await client(`media-upload/mediaFiles/images`, { data });
  });
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, unknown>(
    async (data: any) => await client(`auth/updateProfile`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getLoggedInUser"]);
      },
    }
  );
};

export const useCsvAirtimeDownload = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>(["CsvAirtimeDownload"], async () => {
    const queryString = `service/CsvAirtimeDownload${
      fromDate ? `?fromDate=${fromDate.toString()}` : ""
    }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

    return await client(queryString);
  });

export const useCsvInternetDownload = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>(["CsvInternetDownload"], async () => {
    const queryString = `service/CsvInternetDownload${
      fromDate ? `?fromDate=${fromDate.toString()}` : ""
    }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

    return await client(queryString);
  });

export const useCsvElectricityDownload = (fromDate: string, toDate: string) =>
  useQuery<unknown, Error>(["CsvElectricityDownload"], async () => {
    const queryString = `service/CsvElectricityDownload${
      fromDate ? `?fromDate=${fromDate.toString()}` : ""
    }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

    return await client(queryString);
  });

export const useCsvTvSubscriptionDownload = (
  fromDate: string,
  toDate: string
) =>
  useQuery<unknown, Error>(["CsvTvSubscriptionDownload"], async () => {
    const queryString = `service/CsvTvSubscriptionDownload${
      fromDate ? `?fromDate=${fromDate.toString()}` : ""
    }${toDate ? `&toDate=${toDate.toString()}` : ""}`;

    return await client(queryString);
  });

export const useGetBankList = () =>
  useQuery<any, Error>(
    ["getListOfNigerianBank"],
    async () => await client(`service/getListOfNigerianBank`)
  );

export const useGetBankAccountDetails = () =>
  useQuery<any, Error>(
    ["getAccountDetails"],
    async () => await client(`service/getAccountdetails`)
  );

export const useUpdateBankAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, unknown>(
    async (data: any) =>
      await client(`service/updateCollectionAccount?accountId=${data?.id}`, {
        data: data?.payload,
      }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getAccountDetails"]);
      },
    }
  );
};
