import Layout from "layout";
import React, { useEffect, useState } from "react";
import {
  useGetUserList,
  useGetUserWalletBalance,
  useUpdateUser,
} from "utils/api/user.api";
import { type ListedUser } from "utils/types/user.type";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import avatar from "assets/images/avatar.png";
import Spinner from "components/Spinner";
import ExportPopup from "components/exportPopUp";
import { useGenerateUsersWithBalanceCsv } from "utils/api/auth.api";

export default function UserDictionary() {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") ?? ""
  );
  const [toShow, setToShow] = useState(50);
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [userId, setUserId] = useState("");
  const [exportOption, setExportOption] = useState("All");
  const [isEdit, setIsEdit] = useState("");

  const { data, isLoading, refetch } = useGetUserList(
    undefined,
    toShow,
    searchValue
  );
  const {
    mutate: fetchWalletBalance,
    isLoading: walletLoading,
    data: userWallet,
  } = useGetUserWalletBalance();
  const { data: csvData, isLoading: csvLoading }: any =
    useGenerateUsersWithBalanceCsv();

  const onClickAll = () => {
    const blob = new Blob([csvData], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "users.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    void refetch();
  }, [toShow]);

  useEffect(() => {
    if (userId) {
      fetchWalletBalance(userId);
    }
  }, [userId]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <p className="text-black-900 text-xl">User Directory</p>
            </li>
          </ul>
          <form action="#" className="flex items-center gap-6 mb-8">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                void refetch();
              }}
              placeholder="obasaolorunfemi@gmail.com"
              className="w-full text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-3.5 px-5"
            />
            <button className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3">
              Search
            </button>
          </form>
          <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
            onClickAll={onClickAll}
            // setFromDate={setFromDate}
            // fromDate={fromDate}
            // toDate={toDate}
            // setToDate={setToDate}
            noDates={true}
            load={csvLoading}
          />
          <div className="flex items-center justify-end mt-[10px] mb-6">
            <button
              onClick={() => setExportPopupVisible(true)}
              className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
            >
              Export
            </button>
          </div>

          <div className="table-wrapper max-h-[48rem] overflow-auto">
            <table className="w-full">
              <tbody>
                {data
                  ?.filter(
                    (item) =>
                      !item.isSuperAdmin && item.email.includes(searchValue)
                  )
                  ?.map((item) => (
                    <TableRow
                      key={item?.id}
                      user={item}
                      walletLoading={walletLoading}
                      userWallet={userWallet}
                      setUserId={setUserId}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  ))}
                {!isLoading &&
                  data?.filter(
                    (item: any) =>
                      !item.isSuperAdmin && item.email.includes(searchValue)
                  )?.length === 0 && (
                    <tr>
                      <td>No Results Found</td>
                    </tr>
                  )}
              </tbody>
            </table>
            {data && data?.length > 49 && data?.length === toShow && (
              <div className="flex justify-center">
                <button
                  className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                  onClick={() => setToShow(toShow + 50)}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Load More"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({
  key,
  user,
  walletLoading,
  userWallet,
  setUserId,
  isEdit,
  setIsEdit,
}: {
  key: any;
  user: ListedUser;
  walletLoading: boolean;
  userWallet: any;
  setUserId: any;
  isEdit: any;
  setIsEdit: any;
}) {
  const [isActive, setIsActive] = useState(Boolean(user?.isActive));
  const [userName, setUserName] = useState(String(user?.fullname));
  const [phoneNumber, setPhoneNumber] = useState(String(user?.phone));
  const [email, setEmail] = useState(String(user?.email));
  const [kycTier, setKycTier] = useState(user?.kycTier);
  const [bvn, setBVN] = useState(user?.bvn);

  const { mutate: updateUser, isLoading, isSuccess } = useUpdateUser();

  const handleUpdateUser = (value: string) => setIsActive(value === "active");

  useEffect(() => {
    if (isSuccess) {
      setIsEdit("");
      toast.success("User updated successfully!");
    }
  }, [isSuccess]);

  console.log(kycTier);

  return (
    <>
      {/* <tr>
        <table
          className={`w-full rounded-lg px-2 ${
            isEdit ? "bg-cyan-300" : "bg-black-300"
          }`}
        >
         
        </table>
      </tr> */}
      <tr
        className={`px-2 ${
          user?.role === "User" ? "bg-cyan-300" : "bg-black-300"
        }`}
        key={key}
      >
        <td className="py-5 px-5 rounded-l-lg">
          <div className="flex w-[max-content]">
            {isEdit && (
              <img
                className="w-12 h-12 object-cover rounded-full mr-3"
                src={user?.picture || avatar}
                alt="title"
              />
            )}
            <span className="text-black-900 text-xs">
              Created
              <br />
              {new Date(user?.createdAt).toLocaleDateString("us", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs">{user?.fullname}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs font-medium underline">
            {user?.email}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs">{user?.phone}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs">
            {user?.accountNumber}
            <br />
            {user?.accountName}
          </p>
        </td>
        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          {isEdit !== user?.id && (
            <>
              <span className="text-black-900 text-xs block">{user?.role}</span>
              <button
                className="italic text-black-900 text-xs"
                onClick={() => {
                  setIsEdit(user?.id);
                  setUserId(user?.id);
                }}
              >
                edit user?
              </button>
            </>
          )}
        </td>
      </tr>
      <tr
        className={`${user?.role === "User" ? "bg-cyan-300" : "bg-black-300"} ${
          user?.role === "User" ? "after:bg-cyan-300" : "after:bg-black-300"
        } ${
          isEdit
            ? "relative after:absolute after:left-0 after:bottom-[100%]  after:block after:w-[100%] after:h-[16px]"
            : ""
        }`}
      >
        <td colSpan={6} className="p-0 rounded-b-lg">
          {isEdit === user?.id &&
            (walletLoading ? (
              <div className="flex justify-center m-5">
                <Spinner />
              </div>
            ) : (
              <div className="pb-6 px-6">
                <h4 className="text-xl">Wallet balances</h4>
                <div className="flex my-10 flex-wrap gap-10">
                  {userWallet?.balance?.length &&
                    userWallet?.balance?.map((item: any) => (
                      <div key={item?.id} className="basis-5/12">
                        <h6 className="text-sm font-bold">
                          {item?.symbol} Address
                        </h6>
                        <p className="text-sm">{item?.address}</p>
                        <div className="flex gap-2 mt-2">
                          <img src={item?.logoUrl} className="w-6 h-6" />
                          <span className="font-bold">
                            {new Intl.NumberFormat("en", {
                              maximumFractionDigits: 4,
                            }).format(item?.balance)}{" "}
                            {item?.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  <div className="basis-5/12">
                    <h6 className="text-sm font-bold">Naira Balance</h6>
                    <p className="text-sm mb-2">
                      <span className="font-bold ">Account</span>:
                      {userWallet?.wallet?.accountNumber};{" "}
                      {userWallet?.wallet?.accountName}
                    </p>
                    <span className="font-bold">
                      ₦{" "}
                      {userWallet?.wallet?.currentSwappedBalance &&
                        new Intl.NumberFormat("en", {
                          maximumFractionDigits: 4,
                        }).format(userWallet?.wallet?.currentSwappedBalance)}
                    </span>
                  </div>
                </div>
                <select
                  name="select-1"
                  id="select-1"
                  className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                  onChange={(e) => handleUpdateUser(e?.target?.value)}
                  defaultValue={user?.isActive ? "active" : "de-active"}
                >
                  <option className="bg-black-300" value="active">
                    Activate
                  </option>
                  <option className="bg-black-300" value="de-active">
                    Deactivate
                  </option>
                </select>
                <br />
                <input
                  className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                  placeholder="Enter customer name"
                  defaultValue={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <br />
                <input
                  className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                  placeholder="Enter customer phone number"
                  defaultValue={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <br />
                <input
                  className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                  placeholder="Enter customer email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <select
                  className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                  value={kycTier} // Use value instead of defaultValue
                  onChange={(e) => setKycTier(e.target.value)}
                >
                  <option value="1">1</option>
                  {user?.kycTier === "1" ? <></> : <option value="2">2</option>}
                  {user?.kycTier === "2" || user?.kycTier === "1" ? (
                    <></>
                  ) : (
                    <option value="3">3</option>
                  )}
                </select>
                {bvn && (
                  <>
                    <br />
                    <input
                      className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                      defaultValue={bvn}
                      onChange={(e) => setBVN(e.target.value)}
                      placeholder="Enter customer BVN"
                    />
                  </>
                )}
                <div className="flex justify-end">
                  <button
                    onClick={() => setIsEdit("")}
                    className="mr-2 hover:bg-black-300 text-xs font-bold px-8 py-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() =>
                      updateUser({
                        userId: user?.id,
                        isActive,
                        phone: phoneNumber,
                        fullname: userName,
                        email,
                        kycTier,
                        bvn,
                      })
                    }
                    className="hover:bg-cyan-300 bg-cyan-800 border border-cyan-300 rounded-lg text-black-700 text-xs font-bold transition px-8 py-2"
                  >
                    {isLoading ? (
                      <div
                        className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                      ></div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            ))}
        </td>
      </tr>
    </>
  );
}
