import Spinner from "components/Spinner";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { useGetUserActivities } from "utils/api/auth.api";
// import { type DeviceHistoryData } from "utils/types/user.type";
// import { type DeviceHistoryData } from "utils/types/user.type";

const DeviceHistory = () => {
  const [toShow, setToShow] = useState(50);
  const { data, isLoading, refetch } = useGetUserActivities(toShow, 0, "");
  const loginData = data?.filter((item) => item?.type === "Login");

  useEffect(() => {
    void refetch();
  }, [toShow]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <div className="flex items-center justify-between mt-[10px]">
            <h2 className="text-black font-bold text-xl ">Device History</h2>
          </div>

          <div className="overflow-auto mt-4">
            <div className="table-wrapper max-h-[48rem] overflow-auto">
              <table className="w-full">
                <tbody>
                  <tr className="px-2 bg-cyan-700 text-white  ">
                    <td className="rounded-l-lg  px-5 py-5">
                      <p className="font-bold text-xs">Time</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">User Name</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Email</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Device Name</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">IP</p>
                    </td>
                    <td className="py-5 px-2 rounded-r-lg w-[100px]">
                      <p className="font-bold text-xs ">App Version</p>
                    </td>
                  </tr>
                  {isLoading ? (
                    <Spinner />
                  ) : data?.length === 0 ? (
                    <p>No data available</p>
                  ) : (
                    loginData?.map((data: any) => (
                      <TableRow key={data?.id} data={data} />
                    ))
                  )}
                </tbody>
              </table>
              {data && data?.length > 49 && data?.length === toShow && (
                <div className="flex justify-center">
                  <button
                    className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                    onClick={() => setToShow(toShow + 50)}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeviceHistory;

function TableRow({ data }: { data: any }) {
  return (
    <>
      <tr className="px-2 bg-cyan-300">
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(data?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.userName}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.email}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.deviceName}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.ipAddress}</p>
        </td>

        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          <p className="text-black-900 text-xs ">{data?.appVersion}</p>
        </td>
      </tr>
    </>
  );
}
