import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetFilterTransactions = ({
  coinName,
  status,
  type,
  toShow,
}: {
  coinName: string;
  status: string;
  type: string;
  toShow: number;
}) =>
  useQuery<any>(
    ["filterTransactions"],
    async () =>
      await client(
        `wallet/filterTransactions${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }&coinName=${coinName}&status=${status}&type=${type}`
      )
  );

export const useCsvPendingTransactions = ({
  coinName,
  status,
  type,
  fromDate,
  toDate,
}: {
  coinName: string;
  status: string;
  type: string;
  fromDate: string;
  toDate: string;
}) =>
  useQuery<any>(
    ["CsvPendingTransactions"],
    async () =>
      await client(
        `wallet/CsvPendingTransactions?coinName=${coinName}&status=${status}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`
      )
  );

export const useGetDepositAndWithdrawalAmount = () =>
  useQuery<any[], Error>(
    ["getDepositAndWithdrawalAmount"],
    async () => await client(`wallet/getDepositAndWithdrawalAmount`)
  );

export const useAssignTransactionToAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { transactionId: string; assignTo: string }>(
    async (data: any) =>
      await client(`wallet/AssignTransactionToAdmin`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["filterTransactions"]);
      },
    }
  );
};
export const useRevokeTransactionToAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { transactionId: string; assignTo: string }>(
    async (data: any) =>
      await client(`wallet/revokeTransactionFromAdmin`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["filterTransactions"]);
      },
    }
  );
};

export const useManualVerificationOfPendingTransactions = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { transactionId: string; status: string }>(
    async ({ transactionId, status }) => {
      const queryParams = new URLSearchParams({
        transactionId,
        status,
      });

      return await client(
        `wallet/manualVerificationOfPendingTransactions?${queryParams.toString()}`,
        { method: "POST" }
      );
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["filterTransactions"]);
      },
    }
  );
};
