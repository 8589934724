import React, { useState } from "react";
import Layout from "layout";
import {
  useGetBankAccountDetails,
  useGetBankList,
  useUpdateBankAccountDetails,
} from "utils/api/user.api";
import Spinner from "components/Spinner";
import toast from "react-hot-toast";

export default function BankDetails() {
  const { data: bankDetails, isLoading: bankDetailsLoading } =
    useGetBankAccountDetails();
  const { data: bankList, isLoading: bankListLoading } = useGetBankList();
  const { mutateAsync: updateBankDetails, isLoading } =
    useUpdateBankAccountDetails();
  const [isEdit, setIsEdit] = useState(false);
  const [editForm, setEditForm] = useState({
    bank_name: "",
    account_number: "",
    name: "",
  });
  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl px-6">
          <div className="flex items-center justify-between mt-[20px]">
            <h2 className="text-black font-bold text-xl ">Bank Details</h2>
          </div>
          <div className="table-wrapper max-h-[48rem] overflow-auto">
            {bankDetailsLoading || bankListLoading ? (
              <div className="flex justify-center m-5">
                <Spinner />
              </div>
            ) : (
              <table className="w-full">
                <tbody>
                  <tr className={`px-2 bg-cyan-300`}>
                    <td className="py-5 px-5 rounded-l-lg">
                      <div className="flex w-[max-content]">
                        <span className="text-black-900 text-xs">
                          Last Updated
                          <br />
                          {new Date(bankDetails?.updatedAt).toLocaleDateString(
                            "us",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="py-5 px-2">
                      <p className="text-black-900 text-xs">
                        {bankDetails?.name}
                      </p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="text-black-900 text-xs font-medium underline">
                        {bankDetails?.bank_name}
                      </p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="text-black-900 text-xs">
                        {bankDetails?.account_number}
                      </p>
                    </td>

                    <td className="py-5 px-5 rounded-r-lg w-[100px]">
                      <button
                        className="italic text-black-900 text-xs"
                        onClick={() => {
                          setIsEdit(true);
                          setEditForm({
                            bank_name: bankDetails?.bank_name,
                            account_number: bankDetails?.account_number,
                            name: bankDetails?.name,
                          });
                        }}
                      >
                        edit bank details?
                      </button>
                    </td>
                  </tr>
                  <tr
                    className={`bg-cyan-300 after:bg-cyan-300 ${
                      isEdit
                        ? "relative after:absolute after:left-0 after:bottom-[100%]  after:block after:w-[100%] after:h-[16px]"
                        : ""
                    }`}
                  >
                    <td colSpan={6} className="p-0 rounded-b-lg">
                      {isEdit && (
                        <div className="pb-6 px-6">
                          <h4 className="text-xl">Update Bank Details</h4>

                          <select
                            name="select-1"
                            id="select-1"
                            className="max-w-md mt-5 w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                            onChange={(e) =>
                              setEditForm({
                                ...editForm,
                                bank_name: e.target.value,
                              })
                            }
                            defaultValue={editForm?.bank_name}
                          >
                            {bankList?.map((bank: string, idx: number) => (
                              <option
                                className="bg-black-300"
                                value={bank}
                                key={idx}
                              >
                                {bank}
                              </option>
                            ))}
                          </select>
                          <br />
                          <input
                            className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                            placeholder="Enter account name"
                            defaultValue={editForm?.name}
                            onChange={(e) =>
                              setEditForm({
                                ...editForm,
                                name: e.target.value,
                              })
                            }
                          />
                          <br />
                          <input
                            className="max-w-md w-full border border-black-800/30 px-3 py-1 rounded-lg focus:outline-0 mb-8"
                            placeholder="Enter bank account number"
                            defaultValue={bankDetails?.account_number}
                            onChange={(e) =>
                              setEditForm({
                                ...editForm,
                                account_number: e.target.value,
                              })
                            }
                          />
                          <br />
                          <div className="flex justify-end">
                            <button
                              disabled={isLoading}
                              onClick={() => setIsEdit(false)}
                              className="mr-2 hover:bg-black-300 text-xs font-bold px-8 py-2"
                            >
                              Cancel
                            </button>
                            <button
                              className="hover:bg-cyan-300 bg-cyan-800 border border-cyan-300 rounded-lg text-black-700 text-xs font-bold transition px-8 py-2"
                              onClick={() => {
                                if (
                                  !editForm?.account_number ||
                                  !editForm?.bank_name ||
                                  !editForm?.name
                                ) {
                                  toast.error("Please fill all fields");
                                } else {
                                  updateBankDetails({
                                    payload: editForm,
                                    id: bankDetails?.id,
                                  })
                                    .then(() => {
                                      toast.success("Bank details updated");
                                      setIsEdit(false);
                                    })
                                    .catch((err: any) => {
                                      toast.error(
                                        err?.message ||
                                          "Something went wrong. Please try again!"
                                      );
                                    });
                                }
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <div
                                  className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                                ></div>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
