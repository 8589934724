import Layout from "layout";
import React, { useEffect, useState } from "react";
import { type ReferralHistoryData } from "utils/types/user.type";
import { FaEdit } from "react-icons/fa";
import {
  useGetReferralHistory,
  useUpdateReferralCodeByUserId,
  useUpdateReferralPriceForAllUsers,
} from "utils/api/auth.api";
import toast from "react-hot-toast";
import Spinner from "components/Spinner";

const ReferralHistory = () => {
  const [toShow, setToShow] = useState(50);
  const [searchValue, setSearchValue] = useState<string>("");
  const { data, isLoading, refetch } = useGetReferralHistory(
    toShow,
    searchValue
  );
  const [price, setPrice] = useState<string>(data?.[0]?.RefferalPrice ?? ""); // Ensure price is a string
  const updateReferralPriceMutation = useUpdateReferralPriceForAllUsers();
  const updateReferralCodeMutation = useUpdateReferralCodeByUserId();

  useEffect(() => {
    if (toShow > 50) {
      void refetch();
    }
  }, [toShow]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      void refetch();
    }, 1500);
    return () => clearTimeout(timeout);
  }, [searchValue]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <div className="flex items-center justify-between mt-[10px]">
            <h2 className="text-black font-bold text-xl">Referral History</h2>
            <div className="flex gap-2">
              <input
                name="price"
                className="text-black-700 text-base  rounded-lg border border-black-800 focus:outline-0 py-1 px-2"
                placeholder="Referral Price"
                type="number"
                value={isLoading ? "Loading..." : price}
                readOnly={isLoading}
                // value={editedStatus}
                onChange={(e) => setPrice(e.target.value)}
              />
              <button
                className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-6 lg:px-8 py-1"
                disabled={isLoading}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  try {
                    if (price === "") {
                      toast.error("Code cannot be empty");
                      return;
                    }

                    await updateReferralPriceMutation.mutateAsync({
                      RefferalPrice: price,
                    });

                    toast.success("Referral Price updated successfully!!!");
                  } catch (error: any) {
                    toast.error(error.message);
                  }
                }}
              >
                Update Price
              </button>
            </div>
          </div>
          <form action="#" className="flex items-center gap-6 mb-4 mt-6">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by email"
              className="w-full text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-3.5 px-5"
            />
            <button className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3">
              Search
            </button>
          </form>
          <div className="overflow-auto mt-4">
            <div className="table-wrapper max-h-[48rem] overflow-auto">
              <table className="w-full">
                <tbody>
                  <tr className="px-2 bg-cyan-700 text-white  ">
                    <td className="rounded-l-lg  px-5 py-5">
                      <p className="font-bold text-xs">User</p>
                    </td>

                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Total Earned</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Referral Balance</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Referral Code</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">No of Referrals</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Referral Price</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Referrals</p>
                    </td>

                    <td className="py-5 px-2 rounded-r-lg w-[100px]">
                      <p className="font-bold text-xs ">Action</p>
                    </td>
                  </tr>
                  {isLoading ? (
                    <Spinner />
                  ) : data?.length === 0 ? (
                    <p>No data available</p>
                  ) : (
                    data?.map((data: any) => (
                      <TableRow
                        key={data?.id}
                        data={data}
                        updateReferralCodeMutation={updateReferralCodeMutation}
                      />
                    ))
                  )}
                </tbody>
              </table>
              {data && data?.length > 49 && data?.length === toShow && (
                <div className="flex justify-center">
                  <button
                    className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                    onClick={() => setToShow(toShow + 50)}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReferralHistory;
function TableRow({
  data,
  updateReferralCodeMutation,
}: {
  data: ReferralHistoryData;
  updateReferralCodeMutation: any;
}) {
  const [isEditing, setIsEditing] = useState(false);
  // const [price, setPrice] = useState(data?.RefferalPrice);
  const [code, setCode] = useState(data?.referralCode);
  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <tr className="px-2 bg-cyan-300">
        <td className="rounded-l-lg  px-5 py-5 flex flex-col">
          <p className="text-black-900 text-xs">{data?.UserName}</p>
          <p className="text-black-900 text-xs">{data?.email}</p>
        </td>

        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(data?.totalRewards)?.toLocaleString()}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(data?.RefferalBalance)?.toLocaleString()}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.referralCode}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.NoOfReferrals}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {Number(data?.RefferalPrice)?.toLocaleString()}
          </p>
        </td>
        <td className="py-5 px-2">
          <p
            className="text-black-900 text-xs cursor-pointer"
            onClick={() => setIsEdit(!isEdit)}
          >
            Show?
          </p>
        </td>

        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          <FaEdit
            className="cursor-pointer text-blue-500"
            onClick={() => setIsEditing(!isEditing)}
          />
          {isEditing && (
            <div className="h-[100dvh] w-[100vw] fixed inset-0 bg-[rgba(0,0,0,0.6)] z-[1112]">
              <div className="fixed px-8 py-8 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white p-5 border-2 border-gray-200 rounded-md shadow-lg z-[1113]">
                <button className="absolute top-2 left-2 text-black-700 text-lg cursor-pointer flex items-center"></button>

                <button
                  className="absolute top-2 right-2 text-black-700 text-lg cursor-pointer"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  &#10005; {/* Unicode character for 'X' */}
                </button>

                <div className="flex flex-col justify-center mt-4 w-60">
                  {/* <label
                    htmlFor="price"
                    className="mr-2 mb-2 text-xl text-black-800 font-bold"
                  >
                    Edit Referral Price
                  </label>
                  <input
                    name="price"
                    className="text-black-700 text-base mb-6 rounded-lg border border-black-800 focus:outline-0 py-2 px-2"
                    placeholder="Referral Price"
                    type="number"
                    value={price}
                    // value={editedStatus}
                    onChange={(e) => setPrice(e.target.value)}
                  /> */}
                  <label
                    htmlFor="code"
                    className="mr-2 mb-2 text-xl text-black-800 font-bold"
                  >
                    Edit Referral Code
                  </label>
                  <input
                    name="price"
                    className="text-black-700 text-base mb-6 rounded-lg border border-black-800 focus:outline-0 py-2 px-2"
                    placeholder="Referral Code"
                    type="text"
                    value={code}
                    // value={editedStatus}
                    onChange={(e) => setCode(e.target.value)}
                  />

                  <button
                    className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      try {
                        if (code === "") {
                          toast.error("Code cannot be empty");
                          return;
                        }
                        if (code.length < 5 || code.length > 17) {
                          toast.error(
                            "Code length should be between 5-16 characters"
                          );
                          return;
                        }
                        const formData = {
                          UserId: data?.UserId,
                          RefferalCode: code,
                        };
                        await updateReferralCodeMutation.mutateAsync(formData);
                        setIsEditing(false);
                        toast.success("Referral Code updated successfully!!!");
                      } catch (error: any) {
                        toast.error(error.message);
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </td>
      </tr>
      {isEdit && (
        <tr
          className={`${"bg-cyan-300 after:bg-cyan-300"}
      `}
        >
          <td colSpan={6}>
            {isEdit && (
              <table className="px-6 pb-6">
                <tbody>
                  {/* <p className="text-black-900 text-lg">Referrals:</p> */}
                  <tr>
                    <td className="text-black-900">Name:</td>
                    <td className="text-black-900">Email:</td>
                    <td className="text-black-900">Referral Code:</td>
                  </tr>
                  {data?.referredUsersData?.map((referral, index) => (
                    <tr key={index}>
                      <td className="text-xs" width={"30%"}>
                        {referral?.fullname}
                      </td>
                      <td className="text-xs" width={"30%"}>
                        {referral?.email}
                      </td>
                      <td className="text-xs" width={"30%"}>
                        {referral?.referralCode}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </td>
        </tr>
      )}
    </>
  );
}
