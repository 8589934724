import React from "react";
import Chart from "react-apexcharts";

const ChartComponent = ({
  categories,
  chartSeries,
  h,
  w,
  yTitle,
  naira,
}: any) => {
  const chartOptions: any = {
    chart: {
      id: "basic-bar",
      background: "#EFFFFD",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: yTitle || "Amount (USD)",
        style: {
          fontSize: "14px",
          fontFamily: "Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    tooltip: {
      hideEmptySeries: false,
      y: [
        {
          title: {
            formatter: function (val: any) {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return val + (naira ? " ₦" : "");
            },
          },
        },
        {
          title: {
            formatter: function (val: any) {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return val + (naira ? " ₦" : "");
            },
          },
        },
      ],
    },
    colors: ["#34c759", "#0E7490"],
  };

  const series = chartSeries
    ? chartSeries?.map((dataItem: any) => ({
        name: dataItem?.name,
        data: dataItem?.data,
      }))
    : [];

  return (
    <Chart
      options={chartOptions}
      series={series}
      type="line"
      height={h || 450}
      width={w || 976}
    />
  );
};

export default ChartComponent;
