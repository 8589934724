import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  type VerifyEmailForm,
  type User,
  type Register,
  type UserLogin,
  type SystemStatus,
  type UserActivities,
  type CryptoReserves,
  type DeviceHistoryData,
  type NotificationData,
  type ReferralHistory,
  type UserData,
} from "utils/types/user.type";
import { client } from "./api-client";

export const useLogin = () =>
  useMutation<
    { token: { access_token: string }; user: User },
    Error,
    UserLogin
  >(
    async (data) =>
      await client("auth/loginAdmin", {
        data,
      })
  );

export const useVerifyLogin2FA = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { code: string; token: string }>(
    async ({ code, token }) =>
      await client(`auth/enable2FA/${code}`, {
        method: "POST",
        data: { access_token: token },
      }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getLoggedInUser"]);
      },
    }
  );
};

export const useSignUp = () =>
  useMutation<{ user: User }, Error, Register>(
    async (data) =>
      await client("auth/signupAdmin", {
        data,
      })
  );

export const useVerifyEmail = () =>
  useMutation<
    { user: User; token: { access_token: string } },
    Error,
    VerifyEmailForm
  >(
    async (data) =>
      await client(`auth/verifyEmail`, {
        data,
      })
  );

export const useGetOtp = () =>
  useMutation<unknown, Error, string>(
    async (email) =>
      await client(`auth/forgotPassword`, {
        data: { email },
      })
  );

export const useVerifyOtp = () =>
  useMutation<
    {
      token: {
        access_token: string;
      };
    },
    Error,
    VerifyEmailForm
  >(
    async (data) =>
      await client(`auth/verifyOtpForForgotPassword`, {
        data,
      })
  );

export const useReSendOtp = () =>
  useMutation<unknown, Error, string>(
    async (email) =>
      await client("auth/resendOtp", {
        data: { email },
      })
  );

export const useResetPassword = () =>
  useMutation<unknown, Error, string>(
    async (password) =>
      await client("auth/resetPassword", {
        data: { password },
        headers: {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
          Authorization: localStorage.getItem("otp_token") || "",
        },
      })
  );

export const useChangePassword = () =>
  useMutation<unknown, Error, string>(
    async (password) =>
      await client("user/changePassword", {
        data: { password },
      })
  );

export const useGetSystemStatus = () =>
  useQuery<SystemStatus, Error>(
    ["getSystemStatus"],
    async () => await client(`auth/getSystemStatus`)
  );

export const useGetAllAdmins = (
  limit: number,
  offset: number,
  filterOnlyAdmins: boolean
) =>
  useQuery<UserData, Error>(
    ["getAllUsers", limit, offset, filterOnlyAdmins],
    async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        filterOnlyAdmins: filterOnlyAdmins.toString(), // Convert boolean to string
      });

      const queryString = queryParams.toString(); // Explicitly cast queryParams to string

      return await client(`auth/getAllUsers?${queryString}`);
    }
  );

export const useGetUserActivities = (
  limit: number,
  offset: number,
  type: string
) =>
  useQuery<UserActivities, Error>(
    ["getUserActivities", limit, offset, type],
    async () => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return await client(
        `auth/getUserActivities?limit=${limit}&offset=${offset}&type=${type}`
      );
    }
  );

export const useCsvGetUserActivities = (
  limit: number,
  offset: number,
  fromDate?: string,
  toDate?: string
) =>
  useQuery<UserActivities, Error>(
    ["CsvGetUserActivities", limit, offset, fromDate, toDate],
    async () =>
      await client(
        `auth/CsvGetUserActivities?limit=${limit}&offset=${offset}${
          fromDate ? `&fromDate=${fromDate}` : ""
        }${toDate ? `&toDate=${toDate}` : ""}`
      )
  );

export const useGenerateUsersWithBalanceCsv = () =>
  useQuery<any[], Error>(
    ["generateUsersWithBalanceCsv"],
    async () => await client(`auth/generateUsersWithBalanceCsv`)
  );

export const useGetCryptoReserves = () =>
  useQuery<CryptoReserves, Error>(
    ["getCryptoReserves"],
    async () => await client(`auth/getCryptoReserves`)
  );

export const useSetSystemStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>(
    async (status) =>
      await client("auth/setSystemStatus", {
        data: { status },
      }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getSystemStatus"]);
      },
    }
  );
};
export const useGiveAdminAccess = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    { UserId: string; isAdmin: boolean; isActive: boolean }
  >(
    async (data) =>
      await client("auth/giveAdminAccess", {
        data,
      }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getAllUsers"]);
      },
    }
  );
};

export const useDeviceHistory = () =>
  useQuery<DeviceHistoryData, Error>(
    ["getDeviceHistory"],
    async () => await client(`auth/getDeviceHistory`)
  );

export const useNotificationHistory = (toShow: number) =>
  useQuery<NotificationData, Error>(
    ["notificationHistory", toShow],
    async () =>
      await client(
        `auth/notificationHistory${
          toShow ? `?limit=${toShow}&offset=0` : `?limit=50&offset=0`
        }`
      )
  );

export const useGetReferralHistory = (toShow: number, search: string) =>
  useQuery<ReferralHistory, Error>(
    ["getReferralHistory"],
    async () =>
      await client(
        `auth/getReferralHistory${
          toShow
            ? `?limit=${toShow}&offset=0&search=${search}`
            : `?limit=50&offset=0&search=${search}`
        }`
      )
  );

export const useUpdateReferralPriceForAllUsers = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, any>(
    async (data) =>
      await client("auth/updateReferralPriceForAllUsers", {
        data,
      }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getReferralHistory"]);
      },
    }
  );
};

export const useUpdateReferralCodeByUserId = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>(
    async (data) =>
      await client("auth/updateReferralCodeByUserId", {
        data,
      }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getReferralHistory"]);
      },
    }
  );
};

export const useAddDeviceTokenForAdmin = () => {
  const addDeviceTokenForAdmin = async (deviceToken: any) => {
    const response = await client("auth/addDeviceTokenForAdmin", {
      method: "POST",
      data: deviceToken,
    });

    if (response?.error) {
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(addDeviceTokenForAdmin);
};

export const useRemoveDeviceTokenForAdmin = () => {
  const removeDeviceToken = async (deviceToken: any) => {
    const response = await client("auth/deletDeviceTokenAdmin", {
      method: "POST",
      data: deviceToken,
    });

    if (response?.error) {
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(removeDeviceToken);
};
