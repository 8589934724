import Spinner from "components/Spinner";
import TabNav from "components/tabNav";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { useGetUserActivities } from "utils/api/auth.api";
import { homeLinks } from "views/home";

const types = [
  "Select Type",
  "Login",
  "Registration",
  "Airtime Purchase",
  "Internet Subscription",
  "Electricity Bill",
  "TV Subscription Purchase",
  "Deposit Fiat",
  "Withdraw",
  "Transaction",
  "Swap",
  "Withdrawal Fiat",
];

const UserActivity = () => {
  const [toShow, setToShow] = useState(50);
  const [selectedType, setSelectedType] = useState("");
  const [searchValue] = useState("");
  const {
    data: userActivityData,
    isLoading,
    refetch,
  } = useGetUserActivities(
    toShow,
    0,
    selectedType === "Select Type" ? "" : selectedType
  );
  useEffect(() => {
    void refetch();
  }, [toShow]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={homeLinks} />
          <div className="flex justify-between items-center mt-[30px] mb-4">
            {/* <div className="flex items-center justify-between mt-[30px] mb-4"> */}
            <h2 className="text-black font-bold text-xl ">Activities</h2>
            {/* </div> */}
            <div className="">
              <label htmlFor="transactionType" className="mr-2">
                Activity Type:
              </label>
              <select
                id="transactionType"
                className="text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-1.5 px-2"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                {types.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-[976px]">
            <div
              className={`rounded-md shadow-lg px-6 py-4 flex justify-between items-center text-sm text-white mb-3 bg-cyan-700`}
            >
              <p className="font-bold w-[14%] text-center">Date</p>
              <p className="font-bold w-[24%] text-center">Activity Type</p>
              <p className="font-bold w-[32%] text-center">Details</p>
              <p className="font-bold w-[24%] text-center">UserName</p>
            </div>
          </div>
          <div className=" overflow-auto mt-4">
            <div className="min-w-[700px] w-full ">
              {isLoading ? (
                <Spinner />
              ) : userActivityData?.length === 0 ? (
                <p>No data available </p>
              ) : (
                userActivityData
                  ?.filter(
                    (activity: any) =>
                      activity?.type?.toLowerCase()?.includes(searchValue) ||
                      activity?.userName?.toLowerCase()?.includes(searchValue)
                  )
                  ?.map((activity: any, index: number) => (
                    <div
                      key={index}
                      className={`rounded-2xl px-6 py-3 flex justify-between items-center text-sm my-3 shadow-md bg-cyan-300`}
                    >
                      <p className="font-md w-[14%] text-center">
                        {activity.createdAt.split("T")[0]}
                      </p>
                      <p className="font-md w-[24%] text-center">
                        {activity.type}
                      </p>
                      <p className="font-md w-[32%] text-center">
                        {activity.details}
                      </p>
                      <p className="font-md w-[24%] text-center">
                        {activity.userName}
                      </p>
                    </div>
                  ))
              )}
            </div>
            {userActivityData &&
              userActivityData?.length > 49 &&
              userActivityData?.length === toShow &&
              searchValue?.length === 0 && (
                <div className="flex justify-center">
                  <button
                    className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                    onClick={() => setToShow(toShow + 50)}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserActivity;
