import TabNav from "components/tabNav";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetCoins, useUpdateCoinPrice } from "utils/api/user.api";

export const rateLinks = [
  { to: "/rate-settings", label: "Buy Rate" },
  { to: "/sell-rate", label: "Sell Rate" },
];

const SellRate = () => {
  const [state, setState] = useState<any>({});
  const { data } = useGetCoins();
  const { mutate: updatePrice, isLoading, isSuccess } = useUpdateCoinPrice();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Price updated successfully!");
    }
  }, [isSuccess]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={rateLinks} />
          {/* <div className="flex items-center justify-between mt-[20px]">
         <h2 className="text-black font-bold text-xl ">Rate Settings</h2>
       </div> */}
          {data?.map((item, i) => (
            <div
              key={item?.id}
              className={`bg-cyan-300 rounded-lg flex items-center p-5  gap-4 ${
                i === 0 ? "mt-10" : "mt-5"
              }`}
            >
              <p className="text-lg font-bold basis-5/12 flex items-center gap-4">
                <img src={item?.icon} className="w-10 h-10" /> {item?.symbol}
                /NGN
              </p>
              <div className="basis-2/12 text-right">
                <span className="block text-xs">Former rate</span>
                <span className="font-bold text-[16px]">
                  ₦{" "}
                  {new Intl.NumberFormat("en", {
                    maximumFractionDigits: 4,
                  }).format(item?.priceFormerSell)}
                </span>
              </div>
              <div className="basis-2/12 text-right">
                <span className="block text-xs ">Current rate</span>
                <span className="font-bold text-[16px]">
                  ₦{" "}
                  {new Intl.NumberFormat("en", {
                    maximumFractionDigits: 4,
                  }).format(item?.priceSell)}
                </span>
              </div>
              <input
                onChange={(e) =>
                  setState({ ...state, [`coin${i}`]: e.target.value })
                }
                value={state[`coin${i}`]}
                disabled={isLoading}
                type="number"
                placeholder="Enter new rate"
                className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                onClick={() => {
                  if (state[`coin${i}`]) {
                    updatePrice({
                      priceSell: state[`coin${i}`],
                      coinId: item?.id,
                    });
                    setState({ ...state, [`coin${i}`]: "" });
                  }
                }}
                className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
              >
                {isLoading ? (
                  <div
                    className="w-5 h-5 rounded-full animate-spin mx-auto
                   border-2 border-solid border-black-800 border-t-transparent"
                  ></div>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default SellRate;
