import Layout from "layout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useCsvTransactionsByAdmin,
  useGetTransactions,
} from "utils/api/user.api";
import { type UserTransaction } from "utils/types/user.type";
import ExportPopup from "components/exportPopUp";
import Spinner from "components/Spinner";
import { getMessaging, onMessage } from "firebase/messaging";

const transactionTypes = [
  "All",
  "Deposit",
  "Swap",
  "Withdraw",
  "Transfer",
  "Withdraw Fiat",
  "Deposit Fiat",
];
export default function CompletedTransactions() {
  const messaging = getMessaging();
  const [searchValue, setSearchValue] = useState("");
  const [toShow, setToShow] = useState(50);
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<any>("All");
  const [exportOption, setExportOption] = useState("All");
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const extractSymbol = (fullSymbol: any) => {
    const parts = fullSymbol?.split(" ");
    if (parts) {
      return parts[0];
    }
  };
  const {
    data: completedTrans,
    isLoading,
    refetch,
  } = useGetTransactions(
    "Completed",
    selectedTransactionType === "All" ? undefined : selectedTransactionType,
    toShow
  );
  const {
    data: csvCompletedTrans,
    isLoading: csvLoading,
    refetch: refetchCsv,
  }: any = useCsvTransactionsByAdmin(
    "Completed",
    selectedTransactionType === "All" ? undefined : selectedTransactionType,
    toShow,
    fromDate,
    toDate
  );

  const onClickAll = () => {
    const blob = new Blob([csvCompletedTrans], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "completedTransactions.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    void refetch();
    void refetchCsv();
  }, [toShow]);

  useEffect(() => {
    void refetchCsv();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (searchValue === "") {
      setToShow(50);
    } else {
      setToShow(100000);
    }
  }, [searchValue]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      if (
        payload?.notification?.title?.toLowerCase()?.includes("withdrawal") ||
        payload?.notification?.title?.toLowerCase()?.includes("deposit") ||
        payload?.notification?.title?.toLowerCase()?.includes("swap")
      ) {
        void refetch();
        void refetchCsv();
      }
    });

    return unsubscribe;
  }, []);
  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          {/* <AppStats /> */}

          <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
            onClickAll={onClickAll}
            setFromDate={setFromDate}
            fromDate={fromDate}
            toDate={toDate}
            setToDate={setToDate}
            load={csvLoading}
          />

          <div className="mb-4">
            <label htmlFor="transactionType" className="mr-2">
              Transaction Type:
            </label>
            <select
              id="transactionType"
              className="text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-1.5 px-2"
              value={selectedTransactionType}
              onChange={(e) => setSelectedTransactionType(e.target.value)}
            >
              {transactionTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <form action="#" className="flex items-center gap-6 mb-4">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by date, email address or transaction type"
              className="w-full text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-3.5 px-5"
            />
            <button className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3">
              Search
            </button>
          </form>
          <div className="flex justify-end">
            <button
              onClick={() => setExportPopupVisible(true)}
              className="bg-cyan-300 border border-[green] px-10 py-2 mb-4 rounded-[10px] shadow-lg"
            >
              Export
            </button>
          </div>
          {isLoading ? (
            <div className="flex justify-center mt-2">
              <Spinner />
            </div>
          ) : completedTrans?.length === 0 ? (
            <div className="flex justify-center mt-2">No Records to show</div>
          ) : (
            <div className="table-wrapper max-h-[38rem] overflow-auto">
              <table className="w-full">
                <tbody>
                  {completedTrans
                    ?.filter(
                      (item) =>
                        ((selectedTransactionType === "All" ||
                          extractSymbol(item?.coin?.symbol) ===
                            selectedTransactionType) &&
                          item?.user?.email.includes(searchValue)) ||
                        item?.type.includes(searchValue) ||
                        item?.createdAt.includes(searchValue)
                    )
                    ?.map((transaction) => (
                      <TableRow
                        key={transaction?.id}
                        transaction={transaction}
                      />
                    ))}
                  {!isLoading &&
                    completedTrans?.filter(
                      (item) => item?.coin?.name.includes(searchValue)
                    )?.length === 0 && (
                      <tr>
                        <td>No Results Found</td>
                      </tr>
                    )}
                </tbody>
              </table>
              {completedTrans &&
                completedTrans?.length > 49 &&
                completedTrans?.length === toShow && (
                  <div className="flex justify-center">
                    <button
                      className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                      onClick={() => setToShow(toShow + 50)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Load More"}
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ transaction }: { transaction: UserTransaction }) {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <tr
        className={` ${
          transaction?.type === "Withdraw" || transaction?.type === "Transfer"
            ? "bg-[#FFDEEA]"
            : transaction?.type === "Withdraw Fiat"
            ? "bg-white"
            : transaction?.type.includes("Swap")
            ? "bg-[#F3F3F3]"
            : "bg-cyan-300"
        }`}
      >
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs ml-3">
            {new Date(transaction?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            {transaction?.type === "Transfer" ||
            transaction?.type === "Received"
              ? transaction?.user?.email
              : ""}{" "}
            <b>
              {transaction?.type === "Withdraw Fiat"
                ? "Withdrawal to bank"
                : transaction?.type === "Withdraw"
                ? "Withdrawal"
                : transaction?.type.includes("Swap")
                ? "Naira Swap"
                : transaction?.type}
            </b>{" "}
            {transaction?.type === "Transfer" ? "To" : "from"}{" "}
            {transaction?.type === "Transfer" ||
            transaction?.type === "Received"
              ? transaction?.email
              : transaction?.user?.email}
          </p>
        </td>
        <td className="py-5 px-2 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center">
            {(transaction?.type.includes("Withdraw Fiat") ||
              transaction?.type.includes("Deposit Fiat")) &&
              "₦ "}
            {Number(transaction?.amount)?.toLocaleString(undefined, {
              maximumFractionDigits: 5,
            })}{" "}
            {transaction?.type === "Swap" && transaction?.isToCrypto
              ? "NGN"
              : transaction?.coin?.symbol}
          </p>
        </td>
        <td className="rounded-r-lg px-2 border border-black-800/30 border-l-0 py-5">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`${
              transaction?.type === "Withdraw" ||
              transaction?.type === "Transfer"
                ? "bg-[#FF689F] text-white"
                : transaction?.type === "Withdraw Fiat"
                ? "bg-black-800 text-white"
                : transaction?.type === "Swap"
                ? "bg-[#D4D4D4] text-black"
                : "bg-cyan-800 text-black"
            } text-xs font-semibold rounded-lg px-5 py-2`}
          >
            {isEdit ? "Collapse" : "See Details"}
          </button>
        </td>
      </tr>
      <tr
        className={`${
          transaction?.type === "Withdraw" || transaction?.type === "Transfer"
            ? "bg-[#FFDEEA] after:bg-[#FFDEEA]"
            : transaction?.type === "Withdraw Fiat"
            ? "bg-white after:bg-white"
            : transaction?.type.includes("Swap")
            ? "bg-[#F3F3F3] after:bg-[#F3F3F3]"
            : "bg-cyan-300 after:bg-cyan-300"
        } ${
          isEdit
            ? "relative after:absolute after:border-x after:border-black-800/30 after:left-0 after:bottom-[100%] after:block after:w-[100%] after:h-[18px]"
            : ""
        }
        
        `}
      >
        <td
          colSpan={6}
          className={`p-0 ${
            isEdit ? "border border-black-800/30" : ""
          } rounded-b-lg border-t-0`}
        >
          {isEdit &&
            (transaction?.type === "Withdraw Fiat" ||
            transaction?.type === "Deposit Fiat" ? (
              <table className="px-6 pb-6">
                <tr>
                  {/* <td>
                    <strong className="text-xs">Funds sent to</strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.accountNumber}
                    </span>
                    <span className="text-xs mt-3 block">
                      {transaction?.bankName}
                    </span>
                  </td> */}
                  <td valign="top">
                    <strong className="text-xs">User Remaining Balance</strong>
                    <span className="text-xs mt-3 block">
                      ₦{" "}
                      {new Intl.NumberFormat("en", {
                        maximumFractionDigits: 4,
                      }).format(transaction?.balance)}
                    </span>
                  </td>
                  <td valign="top">
                    <strong className="text-xs">User bank Details</strong>
                    <span className="text-xs mt-3 block">
                      Name: {transaction?.accountName}
                    </span>
                    <span className="text-xs mt-3 block">
                      Number: {transaction?.accountNumber}
                    </span>
                  </td>
                  <td valign="top">
                    <strong className="text-xs">Approved By:</strong>
                    <span className="text-xs mt-3 block">
                      Name: {transaction?.assignedAdmin?.fullname}
                    </span>
                    <span className="text-xs mt-3 block">
                      Email: {transaction?.assignedAdmin?.email}
                    </span>
                  </td>
                  <td valign="top">
                    <Link
                      to={`/user-dictionary?search=${transaction?.user?.email}`}
                      className="text-black-900 text-xs leading-relaxed font-semibold"
                    >
                      Check user in directory
                    </Link>
                  </td>
                </tr>
              </table>
            ) : (
              <table className="px-6 pb-6 w-full">
                <tr>
                  <td>
                    <strong className="text-xs">Transaction details</strong>
                    <strong className="text-xs block mt-3">
                      {transaction?.type === "Withdraw"
                        ? "To wallet address:"
                        : "Incoming wallet address:"}
                    </strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.type === "Withdraw"
                        ? transaction?.toAddress
                        : transaction?.fromAddress}
                    </span>
                  </td>
                  <td valign="top">
                    <strong className="text-xs">From wallet address:</strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.type === "Withdraw"
                        ? transaction?.fromAddress
                        : transaction?.toAddress}
                    </span>
                  </td>
                  {transaction?.type === "Swap" ? (
                    <td valign="top">
                      <strong className="text-xs">Swapped Amount</strong>
                      <span className="text-xs mt-3 block">
                        {!transaction?.isToCrypto && "₦"}{" "}
                        {Number(transaction?.swappedAmount)?.toLocaleString()}{" "}
                        {transaction?.isToCrypto && transaction?.coin?.symbol}
                      </span>
                    </td>
                  ) : transaction?.type === "Withdraw" ? (
                    ""
                  ) : (
                    <td valign="top">
                      <strong className="text-xs">User balance</strong>
                      <span className="text-xs mt-3 block">
                        <img
                          src={transaction?.coin?.icon}
                          className="w-4 h-4 inline mr-1"
                        />{" "}
                        {Number(transaction?.balance)?.toLocaleString()}
                      </span>
                    </td>
                  )}
                  <td valign="top">
                    <span className="text-black-900 text-xs font-semibold mb-3 block">
                      Network: {transaction?.coin?.name}
                    </span>
                    <Link
                      to={`/user-dictionary?search=${transaction?.user?.email}`}
                      className="text-black-900 text-xs leading-relaxed font-semibold"
                    >
                      Check user in directory
                    </Link>
                  </td>
                </tr>
              </table>
            ))}
        </td>
      </tr>
    </>
  );
}
