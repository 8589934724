import Spinner from "components/Spinner";
import ExportPopup from "components/exportPopUp";
import ChakraUIMenu from "components/menu";

import Layout from "layout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useGetAllAdmins, useGiveAdminAccess } from "utils/api/auth.api";
import { useCsvUsers, useGetLoggedInUser } from "utils/api/user.api";
import { type User } from "utils/types/user.type";

export default function AdminAcess() {
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [exportOption, setExportOption] = useState("All");
  const { data, isLoading } = useGetAllAdmins(Number.MAX_SAFE_INTEGER, 0, true);
  const giveAdminAccessMutation = useGiveAdminAccess();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const navigate = useNavigate();

  const { data: csvUsers }: any = useCsvUsers(fromDate, toDate);
  const { data: profile }: any = useGetLoggedInUser();

  const onClickAll = () => {
    const blob = new Blob([csvUsers], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "users.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!profile?.isSuperAdmin) {
      navigate("/");
    }
  }, [profile]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <div className="overflow-auto mt-4">
            <ExportPopup
              isExportPopupVisible={isExportPopupVisible}
              setExportPopupVisible={setExportPopupVisible}
              exportOption={exportOption}
              setExportOption={setExportOption}
              onClickAll={onClickAll}
              setFromDate={setFromDate}
              fromDate={fromDate}
              toDate={toDate}
              setToDate={setToDate}
            />
            <div className="flex items-center justify-between mt-[0px]">
              <h2 className="text-black font-bold text-xl ">Admin Access</h2>
              <button
                onClick={() => setExportPopupVisible(true)}
                className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
              >
                Export
              </button>
            </div>

            <div className="table-wrapper max-h-[48rem] overflow-auto">
              <table className="w-full">
                <tbody>
                  <tr className="px-2 bg-cyan-700 text-white  ">
                    <td className="rounded-l-lg  px-5 py-5">
                      <p className="font-bold text-xs">Time</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Name</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Email</p>
                    </td>
                    <td className="py-5 px-2">
                      <p className="font-bold text-xs ">Status</p>
                    </td>
                    <td className="py-5 px-2 rounded-r-lg w-[100px]">
                      <p className="font-bold text-xs ">Action</p>
                    </td>
                  </tr>
                  {isLoading ? (
                    <Spinner />
                  ) : data?.length === 0 ? (
                    <p>No Data Available</p>
                  ) : (
                    data
                      ?.filter((dat) => !dat?.isSuperAdmin)
                      ?.map((data: any) => (
                        <TableRow
                          key={data?.id}
                          data={data}
                          giveAdminAccessMutation={giveAdminAccessMutation}
                        />
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({
  data,
  giveAdminAccessMutation,
}: {
  data: User;
  giveAdminAccessMutation: any;
}) {
  return (
    <>
      <tr className={`px-2 ${"bg-cyan-300"}`}>
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(data?.updatedAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.fullname}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.email}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">
            {data?.isActive ? "Active" : "InActive"}
          </p>
        </td>

        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          <ChakraUIMenu
            options={data?.isActive ? ["Revoke Admin Access"] : ["Make Admin"]}
            onClick={async () => {
              try {
                const formData = {
                  UserId: data?.id,
                  isAdmin: data?.isAdmin,
                  isActive: !data?.isActive,
                };
                await giveAdminAccessMutation.mutateAsync(formData);
                toast.success("Admin Access changed");
              } catch (error: any) {
                toast.error(error.message);
              }
            }}
          />
        </td>
      </tr>
    </>
  );
}
