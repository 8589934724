import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import Spinner from "components/Spinner";
import CustomModal from "components/customModal";
import ExportPopup from "components/exportPopUp";
import { getMessaging, onMessage } from "firebase/messaging";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaEllipsisH } from "react-icons/fa";
import { useGetLoggedInUser } from "utils/api/user.api";
import {
  useCsvPendingTransactions,
  useGetFilterTransactions,
  useManualVerificationOfPendingTransactions,
  useRevokeTransactionToAdmin,
} from "utils/api/wallet.api";
import { type UserTransaction } from "utils/types/user.type";

export default function PendingTransactions() {
  const messaging = getMessaging();
  const [showModal, setShowModal] = useState(false);
  const [selectedTransactionType] = useState("");
  const [selectedTab, setSelectedTab] = useState("deposited");
  const [exportOption, setExportOption] = useState("All");
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [toShow, setToShow] = useState(50);

  const {
    data: pendingTrans,
    isLoading,
    refetch,
  } = useGetFilterTransactions({
    coinName: selectedTransactionType,
    status: "Pending",
    type:
      selectedTab === "deposited"
        ? "Deposit"
        : selectedTab === "fiat"
        ? "Withdraw Fiat"
        : "Withdraw",
    toShow,
  });
  const { data: loggedInUserData } = useGetLoggedInUser();
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const {
    data: csvData,
    isLoading: csvLoading,
    refetch: refetchCsv,
  }: any = useCsvPendingTransactions({
    coinName: selectedTransactionType,
    status: "Pending",
    type: selectedTab === "deposited" ? "Deposit" : "Withdraw Fiat",
    fromDate,
    toDate,
  });

  const onClickAll = () => {
    const blob = new Blob([csvData], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "pendingTransactions.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchData = () => {
    try {
      void refetch();
      void refetchCsv();
    } catch (error: any) {
      toast.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    void refetchCsv();
  }, [fromDate, toDate]);

  useEffect(() => {
    fetchData();
  }, [toShow]);

  useEffect(() => {
    fetchData();
  }, [selectedTab]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      if (
        payload?.notification?.title?.toLowerCase()?.includes("withdrawal") ||
        payload?.notification?.title?.toLowerCase()?.includes("deposit")
      ) {
        fetchData();
      }
    });

    return unsubscribe;
  }, []);

  console.log(csvLoading);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <div className="mb-5 flex gap-5">
            <button
              className={` text-xl ${
                selectedTab === "deposited"
                  ? "text-black-900"
                  : "text-black-900/20"
              }`}
              onClick={() => setSelectedTab("deposited")}
            >
              Deposits
            </button>

            <button
              className={` text-xl ${
                selectedTab === "fiat" ? "text-black-900" : "text-black-900/20"
              }`}
              onClick={() => setSelectedTab("fiat")}
            >
              Fiat Withdrawals
            </button>

            <button
              className={` text-xl ${
                selectedTab === "crypto"
                  ? "text-black-900"
                  : "text-black-900/20"
              }`}
              onClick={() => setSelectedTab("crypto")}
            >
              Crypto Withdrawals
            </button>
          </div>
          <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
            onClickAll={onClickAll}
            setFromDate={setFromDate}
            fromDate={fromDate}
            toDate={toDate}
            setToDate={setToDate}
            load={csvLoading}
          />

          <div className="flex items-center justify-between">
            <div>
              <button
                onClick={() => setExportPopupVisible(true)}
                className="bg-cyan-300 border border-[green] px-10 py-2  rounded-[10px] shadow-lg"
              >
                Export
              </button>
            </div>
          </div>

          {isLoading ? (
            <div className="mt-3">
              <Spinner />
            </div>
          ) : (
            <div className="table-wrapper max-h-[38rem] overflow-auto">
              <table className="w-full">
                <tbody>
                  {pendingTrans?.length === 0
                    ? "No Records to show"
                    : pendingTrans.map((item: UserTransaction) => (
                        <TableRow
                          loggedInUser={loggedInUserData}
                          key={item?.id}
                          row={item}
                          toggleModal={toggleModal}
                          setTransactionId={setTransactionId}
                        />
                      ))}
                </tbody>
              </table>
              {pendingTrans &&
                pendingTrans?.length > 49 &&
                pendingTrans?.length === toShow && (
                  <div className="flex justify-center">
                    <button
                      className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                      onClick={() => setToShow(toShow + 50)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Load More"}
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      <CustomModal
        showModal={showModal}
        toggleModal={toggleModal}
        id={transactionId}
      />
    </Layout>
  );
}

function TableRow({
  loggedInUser,
  row,
  toggleModal,
  setTransactionId,
}: {
  loggedInUser: any;
  row: UserTransaction;
  toggleModal: any;
  setTransactionId: any;
}) {
  const [isReview, setReview] = useState(false);
  const { mutateAsync: updateTransaction, isLoading } =
    useManualVerificationOfPendingTransactions();
  const assignTransactionToAdminMutation = useRevokeTransactionToAdmin();

  const isAssignedToCurrentUser = row?.assignTo === loggedInUser?.id;

  const removeAssignee = (id: string, assignTo: string) => {
    try {
      const formData = {
        transactionId: id,
        assignTo,
      };
      assignTransactionToAdminMutation
        ?.mutateAsync(formData)
        .then(() => {
          toast.success("Assignee removed Successfully!!!");
          setReview(false);
        })
        .catch((err: any) => {
          toast.error(err.message);
        });
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  return (
    <tr>
      <td className="border-l border-y border-black-800/30 rounded-l-lg py-5 px-2">
        <p className="text-black-900 text-xs ml-3">
          {new Date(row?.createdAt).toLocaleDateString("us", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      </td>
      <td className="border-y border-black-800/30 py-5 px-2">
        <p className="text-black-900 text-xs font-semibold">
          {(row?.type === "Transfer" || row?.type === "Received") &&
            row?.userMakingTransaction?.email}{" "}
          <b>
            {row?.type === "Withdraw Fiat"
              ? "Withdrawal to bank"
              : row?.type === "Withdraw"
              ? "Withdrawal"
              : row?.type.includes("Swap")
              ? "Naira Swap"
              : row?.type}
          </b>{" "}
          {row?.type === "Transfer" ? "to" : "from"} {row?.email}
        </p>
        {isReview && row?.type !== "Withdraw" && (
          <div className=" mt-4">
            <p className="text-black-900 text-xs leading-relaxed font-semibold">
              User bank account:
            </p>
            <p className="text-black-900 text-xs leading-relaxed">
              {row?.accountNumber}
            </p>
            <p className="text-black-900 text-xs leading-relaxed">
              {row?.bankName}
            </p>
          </div>
        )}
      </td>
      <td className="border-y border-black-800/30 py-5 px-2">
        <p className="text-black-900 text-sm text-center">
          Assigned To: {row?.user?.fullname}
        </p>
      </td>
      <td className="border-y border-black-800/30 py-5 px-2">
        <p className="text-black-900 text-sm text-center flex items-center">
          {row?.type === "Withdraw" || row?.type === "Transfer" ? (
            <div className="me-1">
              <img
                src={row?.coin?.icon}
                alt="coin"
                height="16px"
                width="16px"
              />
            </div>
          ) : (
            "₦"
          )}
          {Number(row?.amount)?.toLocaleString(undefined, {
            maximumFractionDigits: 5,
          })}
        </p>
      </td>
      <td className="border-y border-black-800/30 py-5 px-2">
        <p className="text-black-900 text-sm text-center">{row?.status}</p>
      </td>
      <td
        className={`border-y border-black-800/30  py-2 ${
          isReview ? "align-bottom" : ""
        }`}
      >
        {isReview && row?.assignTo !== "" ? (
          <>
            <button
              type="button"
              onClick={() => {
                try {
                  updateTransaction({
                    transactionId: row?.id,
                    status: "Rejected",
                  })
                    .then(() => {
                      toast.success("Transaction Rejected");
                    })
                    .catch(() => {});
                } catch (error: any) {
                  toast.error(error.message);
                }
              }}
              className="hover:bg-black-800 hover:text-white text-black-900 text-xs rounded-lg px-5 py-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <div
                  className="w-5 h-5 rounded-full animate-spin mx-auto
              border-2 border-solid border-cyan-800 border-t-transparent"
                />
              ) : (
                "Reject"
              )}
            </button>

            <button
              onClick={() => {
                try {
                  updateTransaction({
                    transactionId: row?.id,
                    status: "Completed",
                  })
                    .then(() => {
                      toast.success("Transaction Completed");
                    })
                    .catch(() => {});
                } catch (error: any) {
                  toast.error(error.message);
                }
              }}
              type="button"
              className="bg-black-800 text-white text-xs font-semibold rounded-lg px-5 py-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <div
                  className="w-5 h-5 rounded-full animate-spin mx-auto
                border-2 border-solid border-white border-t-transparent"
                ></div>
              ) : (
                "Approve"
              )}
            </button>
          </>
        ) : (
          <></>
        )}
      </td>
      <td className="border-r border-y border-black-800/30 py-5 px-2 rounded-r-lg">
        {row.assignTo !== "" ? (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisH />}
              variant="ghost"
            />
            <MenuList
              bg={"white"}
              p={"10px"}
              border={"1px solid green"}
              zIndex={"20"}
              borderRadius={"10px"}
              minW={"150px"}
              ml={"-160px"}
              className="shadow-lg"
            >
              {isAssignedToCurrentUser && (
                <MenuItem
                  p={"8px"}
                  borderRadius={"5px"}
                  mb={"5px"}
                  _hover={{ backgroundColor: "#EFFFFD" }}
                  onClick={() => setReview(!isReview)}
                >
                  Review
                </MenuItem>
              )}
              {row?.assignTo !== "" ? (
                <MenuItem
                  p={"8px"}
                  borderRadius={"5px"}
                  mb={"5px"}
                  _hover={{ backgroundColor: "#EFFFFD" }}
                  onClick={() => removeAssignee(row?.id, row?.assignTo)}
                >
                  Remove Assignee
                </MenuItem>
              ) : (
                <MenuItem
                  p={"8px"}
                  borderRadius={"5px"}
                  mb={"5px"}
                  _hover={{ backgroundColor: "#EFFFFD" }}
                  onClick={() => {
                    toggleModal();
                    setTransactionId(row?.id);
                  }}
                >
                  Assign
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        ) : row.assignTo === "" ? (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisH />}
              variant="ghost"
            />
            <MenuList
              bg={"white"}
              p={"10px"}
              border={"1px solid green"}
              zIndex={"20"}
              borderRadius={"10px"}
              minW={"150px"}
              ml={"-160px"}
              className="shadow-lg"
            >
              {row?.assignTo !== "" && (
                <MenuItem
                  p={"8px"}
                  borderRadius={"5px"}
                  mb={"5px"}
                  _hover={{ backgroundColor: "#EFFFFD" }}
                  onClick={() => setReview(!isReview)}
                >
                  Review
                </MenuItem>
              )}
              <MenuItem
                p={"8px"}
                borderRadius={"5px"}
                mb={"5px"}
                _hover={{ backgroundColor: "#EFFFFD" }}
                onClick={() => {
                  toggleModal();
                  setTransactionId(row?.id);
                }}
              >
                Assign
              </MenuItem>
            </MenuList>
          </Menu>
        ) : null}
      </td>
    </tr>
  );
}
