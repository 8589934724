import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "assets/images/brand.svg";
import closeIcon from "assets/images/close-icon.svg";
import pTransImg from "assets/images/ChartPieSlice-d.svg";
import homeImg from "assets/images/home.png";
import cTransImg from "assets/images/ShoppingBagOpen-d.svg";
import uDictionary from "assets/images/BookOpen-d.svg";
import deviceHistoryImg from "assets/images/device-history.png";
import paymentsHistoryImg from "assets/images/payments-history.png";
import referralHistoryIcon from "assets/images/users.svg";
import accountUpgradeImg from "assets/images/account-upgrade.png";
import portraitPlaceholder from "assets/images/Portrait_Placeholder.png";

import stImg from "assets/images/FolderNotch-d.svg";
import {
  useGetLoggedInUser,
  useUpdateProfile,
  useUploadFile,
} from "utils/api/user.api";
import toast from "react-hot-toast";
import { requestForToken } from "utils/firebase";
import { useRemoveDeviceTokenForAdmin } from "utils/api/auth.api";

const isValidURL = (url: string): boolean => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const parsedUrl = new URL(url);
    // console.log(parsedUrl);
    return true;
  } catch (error) {
    return false;
  }
};

export default function Layout({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) {
  const navigate = useNavigate();
  const { data: profile, isSuccess }: any = useGetLoggedInUser();
  const removeDeviceToken = useRemoveDeviceTokenForAdmin();

  const uploadFile = useUploadFile();
  const updateProfile = useUpdateProfile();
  const [showDropdown, setShowDropdown] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const handleProfileClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleUploadClick = () => {
    const formData: any = new FormData();
    formData.append("file", profileImage);
    uploadFile
      ?.mutateAsync(formData)
      .then((res: any) => {
        console.log(res);
        updateProfile
          ?.mutateAsync({ image: res?.url })
          .then(() => {
            setShowDropdown(false);
          })
          .catch((error: any) => {
            toast.error(error?.message);
            console.log("error", error);
          });
      })
      .catch((err: any) => {
        toast.error(err?.message);
        console.log("err", err);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/logout");
  };

  const fetchToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        removeDeviceToken
          .mutateAsync({ deviceToken: token })
          .then((res: any) => {
            console.log("logout token remove response", res);
            handleLogout();
          })
          .catch((err: any) => {
            handleLogout();
            console.log("err", err);
          });
      }
    } catch (error: any) {
      handleLogout();
      console.error("Error fetching device token:", error);
    }
  };

  useEffect(() => {
    if (!profile?.isActive && isSuccess) {
      void fetchToken();
    }

    if (
      profile?.isActive &&
      isSuccess &&
      !profile?.isAdmin &&
      !profile?.isSuperAdmin
    ) {
      void fetchToken();
    }
  }, [profile]);

  return (
    <main className="dashboard-section">
      <aside className="dashboard-sidebar bg-white border-r border-black-800/20">
        <div className="sidebar-wrapper h-full relative p-6">
          <button className="sidebar-toggler absolute top-3 right-3 block sm:hidden">
            <img src={closeIcon} alt="title" />
          </button>
          <div className="sidebar-top pb-20">
            <div className="flex items-center mb-6 relative">
              <div>
                <img src={logo} className="mr-2" alt="title" />
                <span className="text-black-900 text-xs font-medium">
                  {profile?.fullname}
                </span>
              </div>
              {isValidURL(profile?.image) ? (
                <img
                  src={profile?.image}
                  className="h-[50px] w-[50px] ml-auto block rounded-[25px] cursor-pointer"
                  onClick={handleProfileClick}
                />
              ) : (
                <img
                  src={portraitPlaceholder}
                  className="h-[50px] w-[50px] ml-auto block rounded-[25px] cursor-pointer"
                  onClick={handleProfileClick}
                  alt="Placeholder"
                />
              )}

              {showDropdown && (
                <div className="absolute top-0 left-0 mt-8">
                  {/* Dropdown content */}
                  <ul className="p-4 border flex flex-col gap-4 bg-cyan-700 text-white cursor-pointer rounded-lg">
                    <li>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e: any) =>
                          setProfileImage(e?.target?.files[0])
                        }
                        className="rounded-lg"
                      />
                    </li>

                    <li>
                      <button
                        disabled={
                          updateProfile?.isLoading || uploadFile?.isLoading
                        }
                        className="bg-cyan-800 text-black-800 font-semibold px-3 py-1 rounded-lg"
                        onClick={handleUploadClick}
                      >
                        {updateProfile?.isLoading || uploadFile?.isLoading
                          ? "Loading..."
                          : "Change"}
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <nav>
              {/* <h5 className="text-black-800/40 text-sm mb-2">Favorites</h5>
              <ol className="mb-6">
                <li>
                  <NavLink
                    to="/pending-transactions"
                    className="flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    Pending Transactions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/completed-transactions"
                    className="flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    Completed Transactions
                  </NavLink>
                </li>
              </ol> */}
              <h5 className="text-black-800/40 text-sm mb-2">Dashboards</h5>
              <ul className="mb-6">
                <li>
                  <NavLink
                    to="/"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img className="mr-2 h-[24px]" src={homeImg} alt="title" />
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/pending-transactions"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img className="mr-2" src={pTransImg} alt="title" />
                    Pending Transactions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/completed-transactions"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img className="mr-2" src={cTransImg} alt="title" />
                    Completed Transactions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/bank-details"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <i className="fa-solid fa-building-columns mr-2 ml-1"></i>{" "}
                    Bank Details
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rate-settings"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img className="mr-2" src={cTransImg} alt="title" />
                    Rate Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user-dictionary"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img className="mr-2" src={uDictionary} alt="title" />
                    User directory
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/device-history"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img
                      className="mr-2 h-[20px]"
                      src={deviceHistoryImg}
                      alt="title"
                    />
                    Device History
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/payments-history"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img
                      className="mr-2 h-[20px]"
                      src={paymentsHistoryImg}
                      alt="title"
                    />
                    Payments History
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Referral-history"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img
                      className="mr-2 h-[20px]"
                      src={referralHistoryIcon}
                      alt="title"
                    />
                    Referral History
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account-level-upgrade"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <img
                      className="mr-2 h-[24px]"
                      src={accountUpgradeImg}
                      alt="title"
                    />
                    Account Level Upgrade
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/notifications"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <div className="relative mr-2 my-[1px] inline-flex w-fit">
                      {true && (
                        <div className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-pink-700 p-1 text-xs"></div>
                      )}
                      <div className="flex items-center justify-center rounded-lg border border-[#5E716E]  p-[2px] text-center text-[#5E716E] shadow-lg dark:text-gray-200">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-4 w-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                    Notifications
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/analytics"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                  >
                    <i className="fa-solid fa-chart-bar mr-2"></i>
                    Analytics & Reporting
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://desk.zoho.com/agent/payremittechnologies/payremit-technologies"
                    target="_blank"
                    className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                    rel="noreferrer"
                  >
                    <img className="mr-2" src={stImg} alt="title" />
                    Support
                  </a>
                </li>
              </ul>
              <h5 className="text-black-800/40 text-sm mb-2">Settings</h5>
              <ul className="mb-6">
                {profile?.isSuperAdmin && (
                  <li>
                    <NavLink
                      to="/admin-access"
                      className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                    >
                      Admin access
                    </NavLink>
                  </li>
                )}
                {profile?.isAdmin && (
                  <li>
                    <NavLink
                      to="/change-email"
                      className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                    >
                      Change email address
                    </NavLink>
                  </li>
                )}
                {profile?.isAdmin && (
                  <li>
                    <NavLink
                      to="/change-password"
                      className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                    >
                      Change password
                    </NavLink>
                  </li>
                )}
                {/* {profile?.isAdmin && !profile?.isSuperAdmin && (
                  <li>
                    <NavLink
                      to="/admin-access"
                      className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                    >
                      Admin Access
                    </NavLink>
                  </li>
                )} */}
                {profile?.isAdmin && (
                  <li>
                    <NavLink
                      to="/2fa"
                      className="aria-[current=page]:bg-cyan-800/20 aria-[current=page]:font-bold hover:bg-cyan-800/20 rounded-lg flex items-center text-black-800 text-sm hover:font-bold transition p-1 mb-2"
                    >
                      Two Factor Authentication
                    </NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="sidebar-bottom w-[15rem] bg-white fixed bottom-0 left-0 right-0 p-6">
            <span
              onClick={() => {
                void fetchToken();
              }}
              className="cursor-pointer text-black-800 text-sm font-bold"
            >
              Logout
            </span>
            <p className="text-black-800 text-xs">Dashboard v1.0</p>
          </div>
        </div>
      </aside>
      {children}
    </main>
  );
}
