import Spinner from "components/Spinner";
import ExportPopup from "components/exportPopUp";
import TabNav from "components/tabNav";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useAddDeviceTokenForAdmin,
  useGetCryptoReserves,
} from "utils/api/auth.api";
import { useCsvCoins, useGetCoins, useGetStats } from "utils/api/user.api";
import { requestForToken } from "utils/firebase";

export const homeLinks = [
  { to: "/", label: "Home" },
  { to: "/system-status", label: "System Status" },
  { to: "/user-activity", label: "User Activity" },
  { to: "/recent-transactions", label: "Recent Transactions" },
];

export default function Home() {
  const AddDeviceToken = useAddDeviceTokenForAdmin();

  const { data, isLoading } = useGetStats();
  const { data: coins, isLoading: coinsLoading } = useGetCoins();
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [exportOption, setExportOption] = useState("All");
  const { data: reservesData, isLoading: reserveLoading } =
    useGetCryptoReserves();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { data: csvData }: any = useCsvCoins(fromDate, toDate);

  const onClickAll = () => {
    const blob = new Blob([csvData], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "coins.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        await AddDeviceToken.mutateAsync({ deviceTokens: token });
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error fetching device token:", error);
    }
  };
  useEffect(() => {
    void fetchToken();
  }, []);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={homeLinks} />
          <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
            onClickAll={onClickAll}
            setFromDate={setFromDate}
            fromDate={fromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
          <div className="grid  items-stretch grid-cols-12 gap-4 lg:gap-8 mt-[50px] mb-12">
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                <div className="flex justify-between mb-3">
                  <p className="text-black-800 text-sm font-semibold ">
                    Total Users
                  </p>
                </div>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    Number(data?.totalUsers)?.toLocaleString() || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                <p className="text-black-800 text-sm font-semibold mb-3">
                  Active Users
                </p>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    Number(data?.activeUsers)?.toLocaleString() || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                <p className="text-black-800 text-sm font-semibold mb-3">
                  New Users
                </p>
                <div className="flex gap-[10px] justify-between text-xs">
                  <div className="flex flex-col justify-center items-center gap-2">
                    <p>Today</p>
                    <h4 className="text-black-800 text-2xl font-semibold">
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        Number(data?.newUsers?.today)?.toLocaleString() || 0
                      )}
                    </h4>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-2">
                    <p>This Week</p>
                    <h4 className="text-black-800 text-2xl font-semibold">
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        Number(data?.newUsers?.thisWeek)?.toLocaleString() || 0
                      )}
                    </h4>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-2">
                    <p>This Month</p>
                    <h4 className="text-black-800 text-2xl font-semibold">
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        Number(data?.newUsers?.thisMonth)?.toLocaleString() || 0
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                <div className="flex justify-between mb-3">
                  <p className="text-black-800 text-sm font-semibold ">
                    Total Withdrawals
                  </p>
                </div>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    Number(
                      data?.totalWithdrawalBalance?.toFixed(3)
                    )?.toLocaleString() ?? 0
                  )}
                </h4>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                <p className="text-black-800 text-sm font-semibold mb-3">
                  Total Deposits
                </p>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    Number(
                      data?.totalDepositBalance?.toFixed(3)
                    )?.toLocaleString() ?? 0
                  )}
                </h4>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                <p className="text-black-800 text-sm font-semibold mb-3">
                  Transaction Volume
                </p>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    Number(
                      data?.transactionVolume?.toFixed(3)
                    )?.toLocaleString() ?? 0
                  )}
                </h4>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-black font-bold text-xl mt-[80px]">
              Cryptocurrency Reserves
            </h2>
            <div className="grid  items-stretch grid-cols-12 gap-4 lg:gap-8 mt-[30px] mb-5">
              {/* <div className="col-span-12 md:col-span-6">
                <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                  <p className="text-black-800 text-sm font-semibold mb-3">
                    Available Reservs
                  </p>
                  <h4 className="text-black-800 text-2xl font-semibold">
                    576980
                  </h4>
                </div>
              </div> */}
              <div className="col-span-12 md:col-span-6">
                <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6 h-full">
                  <p className="text-black-800 text-sm font-semibold mb-3">
                    Total Reservs
                  </p>
                  <h4 className="text-black-800 text-2xl font-semibold">
                    {reserveLoading ? (
                      <Spinner />
                    ) : (
                      Number(reservesData?.totalReserve)?.toLocaleString() || 0
                    )}
                  </h4>
                </div>
              </div>
            </div>
            <div className=" overflow-auto">
              <div className="min-w-[500px] w-full ">
                <div className="mb-4 ">
                  <button
                    onClick={() => setExportPopupVisible(true)}
                    className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg block ml-auto"
                  >
                    Export
                  </button>
                </div>
                {coinsLoading ? (
                  <Spinner />
                ) : coins?.length === 0 ? (
                  <p>No data available</p>
                ) : (
                  coins?.map((coin: any, index) => (
                    <div
                      key={index}
                      className="bg-cyan-300 shadow-md items-center rounded-2xl px-6 py-3 flex justify-between my-3"
                    >
                      <div className="flex gap-[30px] items-center">
                        <img
                          className="h-[40px]"
                          src={coin.icon}
                          alt={`Coin ${index}`}
                        />
                        <p className="text-lg font-md">
                          {coin.symbol?.includes("USDC")
                            ? "USDC"
                            : coin?.symbol?.includes("USDT")
                            ? "USDT"
                            : coin?.symbol}
                        </p>
                      </div>
                      <p className="text-lg font-md">
                        {Number(coin?.priceMarket)?.toLocaleString()}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
