import React, { useEffect, useState } from "react";
import welcomeBack from "assets/images/welcome-back.png";
import { Link, useNavigate } from "react-router-dom";
import { useLogin, useVerifyLogin2FA } from "utils/api/auth.api";
import { type UserLogin } from "utils/types/user.type";
import { type SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Input } from "components/input";

export default function Login() {
  const navigate = useNavigate();
  const [is2FA, setIs2FA] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [loggedInUser, setLoggedInUser] = useState<any>(null);

  const { mutate: userLogin, isSuccess, data, isLoading } = useLogin();
  const verify2FA = useVerifyLogin2FA();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserLogin>();

  const onSubmit: SubmitHandler<UserLogin> = (data) => userLogin(data);

  const callLogin = (token: any, role: any) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userRole", role);
    toast.success("Login successfully!");
    navigate("/");
  };
  useEffect(() => {
    if (isSuccess) {
      if (data?.user?.isAdmin) {
        if (data?.user?.is2FAEnabled) {
          setIs2FA(true);
          setLoggedInUser({
            token: data?.token?.access_token?.split(" ")[1],
            userRole: data?.user?.role,
          });
        } else {
          callLogin(data?.token?.access_token?.split(" ")[1], data?.user?.role);
        }
      } else {
        toast.error("Only admins can login!");
      }
    }
  }, [isSuccess]);
  return (
    <section className="login-section">
      <div className="grid lg:grid-cols-12 h-screen gap-3">
        <div className="lg:col-span-6 relative lg:overflow-hidden">
          <div className="login-bg h-full flex items-end">
            <img
              className="max-w-xs lg:max-w-none w-full h-full object-cover object-right-top"
              src={welcomeBack}
              alt="title"
            />
          </div>
        </div>
        <div className="lg:col-span-6 flex items-center justify-center">
          <div className="login-wrapper w-full max-w-md mx-auto p-6">
            <h5 className="text-center text-black-800 text-3xl font-bold mb-8 xl:mb-16">
              Welcome back
            </h5>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises  */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <Input
                  isInvalid={Boolean(errors?.email)}
                  errorMessage={errors?.email?.message}
                  label="Email"
                  type="email"
                  name="email"
                  register={register}
                  rules={{
                    required: "Email is required",
                  }}
                />
              </div>

              <div className="mb-4">
                <Input
                  isInvalid={Boolean(errors?.password)}
                  errorMessage={errors?.password?.message}
                  label="Password"
                  type="password"
                  name="password"
                  register={register}
                  rules={{
                    required: "Password is required",
                  }}
                />
              </div>

              <div className="mb-4 lg:mb-14">
                <div className="text-right">
                  <Link to="/forgot-password">
                    <b>Forgot Password?</b>
                  </Link>
                </div>
              </div>

              <div className="lg:mx-8 mb-8">
                <button
                  type="submit"
                  className="bg-cyan-800 hover:bg-cyan-300 text-black-800 text-xl font-medium border border-cyan-800 rounded-lg w-full p-3.5 transition"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      className="w-8 h-8 rounded-full animate-spin mx-auto
                      border-4 border-solid border-cyan-800 border-t-transparent"
                    ></div>
                  ) : (
                    "Login to your account"
                  )}
                </button>
              </div>
              <div className="text-center">
                <Link
                  to="/signup"
                  className="text-stone-700 text-xl font-light"
                >
                  Create an account instead?
                </Link>
              </div>
            </form>
          </div>
        </div>
        {/* 2FA Modal */}
        {/* Modal */}
        {is2FA && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-full max-w-md">
              <div className="mb-4">
                <h2 className="text-center text-2xl font-bold mb-4">
                  Verify Two-Factor Authentication
                </h2>

                {/* Verification code input */}
                <div className="mb-4">
                  <label
                    htmlFor="verificationCode"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Verification Code:
                  </label>
                  <input
                    type="text"
                    id="verificationCode"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter 6 digit verification code..."
                    value={verificationCode}
                    onChange={(e) => {
                      if (verificationCode?.length <= 6) {
                        setVerificationCode(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              {/* Close button */}

              <div className="flex justify-center gap-4">
                <button
                  disabled={verify2FA?.isLoading}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={() => setIs2FA(false)}
                  className="p-1 rounded px-2 bg-red-400 hover:bg-red-700 text-white font-bold"
                >
                  Close
                </button>
                <button
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  disabled={verify2FA?.isLoading}
                  onClick={() => {
                    if (verificationCode?.length < 6) {
                      toast.error("Please enter 6 digit code.");
                    } else {
                      verify2FA
                        ?.mutateAsync({
                          code: verificationCode,
                          token: loggedInUser?.token,
                        })
                        .then((res: any) => {
                          if (res?.isValid) {
                            callLogin(loggedInUser?.token, loggedInUser?.role);
                          } else {
                            toast.error("Invalid OTP");
                          }
                        })
                        .catch((err: any) => {
                          toast.error(err?.message);
                        });
                    }
                  }}
                  className="bg-green-400 hover:bg-green-700 text-white font-bold p-1 rounded px-2 "
                >
                  {verify2FA?.isLoading ? "Loading..." : "Verify"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
