import ExportPopup from "components/exportPopUp";
import TabNav from "components/tabNav";
import { getMessaging, onMessage } from "firebase/messaging";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import {
  useCsvTvSubscriptionDownload,
  useGetCompletedvSubscription,
} from "utils/api/user.api";
import { type TVSubs } from "utils/types/user.type";
import { paymentLinks } from "views/paymentsHistory";

const TVSubscription = () => {
  const messaging = getMessaging();
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [exportOption, setExportOption] = useState("All");
  const [toShow, setToShow] = useState(50);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { data, isLoading, refetch } = useGetCompletedvSubscription(toShow);
  const {
    data: csvData,
    isLoading: csvLoading,
    refetch: refetchCsv,
  }: any = useCsvTvSubscriptionDownload(fromDate, toDate);
  const onClickAll = () => {
    const blob = new Blob([csvData], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "TvSubscription.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    void refetchCsv();
  }, [fromDate, toDate]);

  useEffect(() => {
    void refetch();
  }, [toShow]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      if (
        payload?.notification?.title?.toLowerCase()?.includes("Tv Subscription")
      ) {
        void refetch();
        void refetchCsv();
      }
    });

    return unsubscribe;
  }, []);
  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={paymentLinks} />
          <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
            onClickAll={onClickAll}
            setFromDate={setFromDate}
            fromDate={fromDate}
            toDate={toDate}
            setToDate={setToDate}
            load={csvLoading}
          />
          <div className="flex items-center justify-between mt-[10px]">
            <h2 className="text-black font-bold text-xl ">Payments History</h2>
            <button
              onClick={() => setExportPopupVisible(true)}
              className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
            >
              Export
            </button>
          </div>
          <div className="table-wrapper max-h-[38rem] overflow-auto">
            <table className="w-full">
              <tbody>
                {data?.map((transaction) => (
                  <TableRow key={transaction?.id} transaction={transaction} />
                ))}
                {!isLoading && data?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {data && data?.length > 49 && data?.length === toShow && (
              <div className="flex justify-center">
                <button
                  className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                  onClick={() => setToShow(toShow + 50)}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Load More"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TVSubscription;

function TableRow({ transaction }: { transaction: TVSubs }) {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <tr className={` ${"bg-cyan-300"}`}>
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs ml-3">
            {new Date(transaction?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            TV Subscription purchased by {transaction?.customerName}
          </p>
        </td>
        <td className="py-5 px-2 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center">
            ₦ {Number(transaction?.amount)?.toLocaleString()}
          </p>
        </td>

        <td className="rounded-r-lg px-2 border border-black-800/30 border-l-0 py-5 text-end">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`${"bg-cyan-800 text-black"} text-xs font-semibold rounded-lg px-5 py-2`}
          >
            {isEdit ? "Collapse" : "See Details"}
          </button>
        </td>
      </tr>
      <tr
        className={`${"bg-cyan-300 after:bg-cyan-300"} ${
          isEdit
            ? "relative after:absolute after:border-x after:border-black-800/30 after:left-0 after:bottom-[100%] after:block after:w-[100%] after:h-[18px]"
            : ""
        }
        
        `}
      >
        <td
          colSpan={6}
          className={`p-0 ${
            isEdit ? "border border-black-800/30" : ""
          } rounded-b-lg border-t-0`}
        >
          {isEdit && (
            <table className="px-6 pb-6">
              <tr>
                <td>
                  <strong className="text-xs">Service Provider</strong>
                  <span className="text-xs mt-3 block">
                    {transaction?.provider || "N/A"}
                  </span>
                </td>
                <td valign="top">
                  <strong className="text-xs">Smart Card Number</strong>
                  <span className="text-xs mt-3 block">
                    ₦{" "}
                    {new Intl.NumberFormat("en", {
                      maximumFractionDigits: 4,
                    }).format(transaction?.smartCardNo)}
                  </span>
                </td>
                <td valign="top">
                  <strong className="text-xs">Transaction ID</strong>
                  <span className="text-xs mt-3 block">{transaction?.id}</span>
                </td>
              </tr>
            </table>
          )}
        </td>
      </tr>
    </>
  );
}
