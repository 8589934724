import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes";
import toast, { Toaster } from "react-hot-toast";
import { getMessaging, onMessage } from "firebase/messaging";

const messaging: any = getMessaging();

function App() {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      toast(
        (t) => (
          <div>
            <h3>{payload?.notification?.title}</h3>
            <p>{payload?.notification?.body}</p>
          </div>
        ),
        {
          position: "top-right",
        }
      );
    });

    return unsubscribe;
  }, []);

  return (
    <BrowserRouter>
      <AppRoutes />
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
