import TabNav from "components/tabNav";
import Layout from "layout";
import React, { useState } from "react";
import { homeLinks } from "views/home";
import { FaEdit } from "react-icons/fa";
import { useGetSystemStatus, useSetSystemStatus } from "utils/api/auth.api";
import Spinner from "components/Spinner";

const SystemStatus = () => {
  const [status, setStatus] = useState("Online");
  const [isEditing, setIsEditing] = useState(false);
  const [editedStatus, setEditedStatus] = useState(status);
  const { data: systemStatusData, isLoading: systemStatusLoading } =
    useGetSystemStatus();
  const setSystemStatusMutation = useSetSystemStatus();
  console.log(systemStatusData);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      await setSystemStatusMutation.mutateAsync(editedStatus);
      setStatus(editedStatus);
      setIsEditing(false);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const handleCancelClick = () => {
    // If the user cancels, revert to the original status
    setEditedStatus(status);
    setIsEditing(false);
  };

  console.log(status);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={homeLinks} />
          {/* <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
          /> */}
          <div className="bg-cyan-300 rounded-2xl transition px-6 py-3 shadow-xl w-full flex justify-between items-center">
            <p className="text-black-800 text-2xl font-semibold mb-0">Status</p>
            <div className="flex items-center gap-3">
              {isEditing && (
                <div className="h-[100dvh] w-[100vw] fixed inset-0 bg-[rgba(0,0,0,0.6)] z-[1112]">
                  <div className="fixed px-8 py-8 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white p-5 border-2 border-gray-200 rounded-md shadow-lg z-[1113]">
                    <button className="absolute top-2 left-2 text-black-700 text-lg cursor-pointer flex items-center"></button>

                    <button
                      className="absolute top-2 right-2 text-black-700 text-lg cursor-pointer"
                      onClick={handleCancelClick}
                    >
                      &#10005; {/* Unicode character for 'X' */}
                    </button>

                    <div className="flex flex-col justify-center mt-4 w-60">
                      <label
                        htmlFor="status"
                        className="mr-2 mb-2 text-xl text-black-800 font-bold"
                      >
                        Status
                      </label>
                      <select
                        id="status"
                        className="text-black-700 text-base mb-6 rounded-lg border border-black-800 focus:outline-0 py-2 px-2 cursor-pointer"
                        value={editedStatus}
                        onChange={(e) => setEditedStatus(e.target.value)}
                      >
                        <option value="Online">Online</option>
                        <option value="Maintenance">Maintenance</option>
                        <option value="Issues">Issues</option>
                      </select>

                      <button
                        className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleSaveClick}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <h4
                className={` text-${
                  status === "Online" ? "green-500" : "red-500"
                } text-2xl font-semibold`}
              >
                {systemStatusLoading ? (
                  <Spinner />
                ) : systemStatusData?.status === "" || !systemStatusData ? (
                  "Online"
                ) : (
                  systemStatusData?.status
                )}
              </h4>
              <button
                onClick={handleEditClick}
                className="text-blue-500   font-semibold"
              >
                <FaEdit />
              </button>
            </div>
          </div>
          {/* <div className="flex items-center justify-between mt-[80px]">
            <h2 className="text-black font-bold text-xl ">Incidents</h2>
          </div>
          <div className=" overflow-auto">
            <div className="min-w-[900px] w-full ">
              <div className="rounded-md shadow-lg px-6 py-4 flex justify-between items-center text-sm text-white my-3 bg-cyan-700">
                <p className="font-bold ">Date</p>
                <p className="font-bold ">Activity Type</p>
                <p className="font-bold max-w-[300px]">Details</p>
                <p className="font-bold  text-end">Status</p>
              </div>
              {systemStatusLoading ? (
                <Spinner />
              ) : !systemStatusData?.incidents ? (
                <p>No data available</p>
              ) : (
                systemStatusData?.incidents?.map(
                  (report: any, index: number) => (
                    <div
                      key={index}
                      className={`rounded-2xl px-6 py-3 shadow-md flex justify-between text-sm items-center my-3 ${
                        report.status === "Open" ? "bg-red-300" : "bg-cyan-300"
                      }`}
                    >
                      <p className="font-md">{report.date.split("T")[0]}</p>
                      <p className="font-md">{report.type}</p>
                      <p className="font-md max-w-[300px]">{report.details}</p>
                      <p className="font-md">{report.status}</p>
                    </div>
                  )
                )
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mt-[80px]">
            <h2 className="text-black font-bold text-xl ">
              Maintenance Schedules
            </h2>
          </div>
          <div className=" overflow-auto">
            <div className="min-w-[900px] w-full ">
              <div className="rounded-md shadow-lg px-6 py-4 flex justify-between items-center text-sm text-white my-3 bg-cyan-700">
                <p className="font-bold  ">Date</p>
                <p className="font-bold ">Type</p>
                <p className="font-bold  text-end">Status</p>
              </div>
              {systemStatusLoading ? (
                <Spinner />
              ) : !systemStatusData?.incidents ? (
                <p>No data available</p>
              ) : (
                systemStatusData?.maintenanceSchedule?.map(
                  (schedule: any, index: number) => (
                    <div
                      key={index}
                      className={`rounded-2xl px-6 py-3 flex justify-between text-sm my-3 shadow-md ${
                        schedule.status === "Completed"
                          ? "bg-cyan-300"
                          : "bg-yellow-100"
                      }`}
                    >
                      <p className="font-md">{schedule.date.split("T")[0]}</p>
                      <p className="font-md">{schedule.type}</p>
                      <p className="font-md">{schedule.status}</p>
                    </div>
                  )
                )
              )}
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default SystemStatus;
