import React, { useState } from "react";
import QRCode from "qrcode.react";
import Layout from "layout";
import {
  useEnable2Fa,
  useEnable2FaOtp,
  useGetLoggedInUser,
  useUpdateProfile,
} from "utils/api/user.api";
import toast from "react-hot-toast";
const TwoFactorSetupPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const { data } = useEnable2Fa();
  const { data: loggedInData } = useGetLoggedInUser();
  const updateProfile = useUpdateProfile();
  console.log(data);

  const handleEnable2FA = () => {
    setIsModalOpen(true);
    // const generatedSecretKey = "ABCDEF123456"; // Example secret key
    setSecretKey(data?.authSecretText);
  };

  const enable2FaMutation = useEnable2FaOtp();

  const handleModalSubmit = async () => {
    try {
      if (verificationCode === "") {
        toast.error("Please enter verification code");
        return;
      }
      if (verificationCode.length > 6 || verificationCode.length < 6) {
        toast.error("Verification Code must be 6 digits");
        return;
      }
      const res: any = await enable2FaMutation.mutateAsync(verificationCode);
      console.log(res);
      if (res?.isValid && loggedInData?.is2FAEnabled) {
        await updateProfile.mutateAsync({ is2FAEnabled: false });
        toast.success("2FA disabled successfully!!!");
        setIsModalOpen(false);
        setSecretKey("");
        setVerificationCode("");
        // return;
      } else if (res?.isValid) {
        toast.success("2FA verified successfully!!!");
        setIsModalOpen(false);
        setSecretKey("");
        setVerificationCode("");
      } else {
        toast.error("Invalid Otp!!!");
        setVerificationCode("");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const handleModalClose = async () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <h2 className="text-xl font-bold mb-5">Two-Factor Authentication</h2>
          <div className="h-[90dvh] flex items-center justify-center bg-gray-100">
            <div className="bg-white shadow-2xl border rounded-[15px] px-8 pt-6 pb-8 mb-4 flex flex-col w-full w-[95%] max-w-md mx-auto">
              <h2 className="text-center text-2xl font-bold mb-6">
                Two-Factor Authentication Settings
              </h2>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-700 text-sm">
                  Two-factor authentication adds an extra layer of security to
                  your account. Once enabled, you will need to provide a
                  verification code along with your password.
                </p>
              </div>
              <div className="mb-4 flex justify-between gap-2 flex-col md:flex-row">
                <button
                  disabled={loggedInData?.is2FAEnabled}
                  onClick={handleEnable2FA}
                  style={{
                    cursor: loggedInData?.is2FAEnabled
                      ? "not-allowed"
                      : "pointer",
                  }}
                  className={`bg-green-400 w-full md:w-auto shadow-xl ${
                    loggedInData?.is2FAEnabled ? "" : "hover:bg-green-700"
                  } text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline`}
                >
                  Enable 2FA
                </button>

                <button
                  onClick={handleEnable2FA}
                  disabled={!loggedInData?.is2FAEnabled}
                  style={{
                    cursor: !loggedInData?.is2FAEnabled
                      ? "not-allowed"
                      : "pointer",
                  }}
                  className={`bg-red-400 w-full md:w-auto shadow-xl ${
                    !loggedInData?.is2FAEnabled ? "" : "hover:bg-red-700"
                  } text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline`}
                >
                  Disable 2FA
                </button>
              </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-full max-w-md">
                  <div className="mb-4">
                    <h2 className="text-center text-2xl font-bold mb-4">
                      Enable Two-Factor Authentication
                    </h2>
                    {/* Form for enabling 2FA */}
                    <form>
                      {/* Secret key input */}
                      <div className="mb-4">
                        <label
                          htmlFor="secretKey"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Secret Key:
                        </label>
                        <input
                          type="text"
                          id="secretKey"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter secret key..."
                          value={secretKey}
                          onChange={(e) => setSecretKey(e.target.value)}
                          disabled
                        />
                      </div>

                      {/* Verification code input */}
                      <div className="mb-4">
                        <label
                          htmlFor="verificationCode"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Verification Code:
                        </label>
                        <input
                          type="text"
                          id="verificationCode"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter verification code..."
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                        />
                      </div>

                      {/* QR code display */}
                      <div className="mb-4 flex justify-center">
                        <QRCode value={data?.authUrl} />
                      </div>
                    </form>
                  </div>
                  {/* Close button */}

                  <div className="flex justify-center gap-4">
                    <button
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={handleModalClose}
                      className="p-1 rounded px-2 bg-red-400 hover:bg-red-700 text-white font-bold"
                    >
                      Close
                    </button>
                    <button
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={handleModalSubmit}
                      className="bg-green-400 hover:bg-green-700 text-white font-bold p-1 rounded px-2 "
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TwoFactorSetupPage;
