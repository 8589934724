import React, { useState } from "react";
import toast from "react-hot-toast";
import { useGetAllAdmins } from "utils/api/auth.api";
import { useAssignTransactionToAdmin } from "utils/api/wallet.api";

function CustomModal({ showModal, toggleModal, id }: any) {
  const { data } = useGetAllAdmins(Number.MIN_SAFE_INTEGER, 0, true);
  const assignTransactionToAdminMutation = useAssignTransactionToAdmin();
  const [userId, setUserId] = useState(""); // State to hold the selected userId

  const handleSelectChange = (event: any) => {
    const selectedUserId = event.target.value;
    setUserId(selectedUserId);
  };
  return (
    <div>
      <button
        data-modal-target="authentication-modal"
        data-modal-toggle="authentication-modal"
        className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => {
          toggleModal();

          // onSubmit();
        }}
      >
        Assign
      </button>

      {/* <div
        className={`h-[100vh] w-[100vw] fixed inset-0 bg-[rgba(0,0,0,${
          !isFromAnalytics ? 0.6 : 0.3
        })] z-[1112]`}
      > */}

      {showModal && (
        <div
          id="authentication-modal"
          tabIndex={-1}
          aria-hidden="true"
          className=" fixed top-0 right-0 left-0 z-[1112] inst-0 flex justify-center items-center w-[100vw] h-[100vh] bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 ">
                  Assign Admin
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <form
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={async (e) => {
                    e.preventDefault();

                    try {
                      const formData = {
                        transactionId: id,
                        assignTo: userId,
                      };
                      await assignTransactionToAdminMutation?.mutateAsync(
                        formData
                      );
                      toast.success(
                        "Transaction Assigned to Admin Successfully!!!"
                      );
                      toggleModal();
                    } catch (error: any) {
                      toast.error(error.message);
                    }
                  }}
                >
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <div className="mt-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-12">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Admins
                          </label>
                          <div className="mt-2">
                            <select
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-400 sm:max-w-xs sm:text-sm sm:leading-6"
                              onChange={handleSelectChange}
                              value={userId} // Controlled component, ensures that the value is controlled by state
                            >
                              {data?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                  {option?.fullname}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                      onClick={toggleModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-6 lg:px-12 py-2"
                    >
                      Assign
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomModal;
