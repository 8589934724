import ExportPopup from "components/exportPopUp";
import TabNav from "components/tabNav";
import { getMessaging, onMessage } from "firebase/messaging";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useCsvTransactionsByAdmin,
  useGetTransactions,
} from "utils/api/user.api";
import { type UserTransaction } from "utils/types/user.type";
import { homeLinks } from "views/home";

const RecenetTransactions = () => {
  const messaging = getMessaging();
  const [isExportPopupVisible, setExportPopupVisible] = useState(false);
  const [exportOption, setExportOption] = useState("All");
  const [fromDate, setFromDate] = useState("");
  const [toShow, setToShow] = useState(50);
  const [toDate, setToDate] = useState("");
  const {
    data: completedTrans,
    isLoading,
    refetch,
  } = useGetTransactions("Completed", undefined, toShow);

  const {
    data: csvData,
    refetch: refetchCsv,
    isLoading: csvLoading,
  }: any = useCsvTransactionsByAdmin(
    "Completed",
    undefined,
    toShow,
    fromDate,
    toDate
  );

  const onClickAll = () => {
    const blob = new Blob([csvData], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "recent_transactions.csv";
    console.log(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    void refetch();
    void refetchCsv();
  }, [toShow]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      void refetch();
      void refetchCsv();
    });

    return unsubscribe;
  }, []);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={homeLinks} />
          <ExportPopup
            isExportPopupVisible={isExportPopupVisible}
            setExportPopupVisible={setExportPopupVisible}
            exportOption={exportOption}
            setExportOption={setExportOption}
            onClickAll={onClickAll}
            setFromDate={setFromDate}
            fromDate={fromDate}
            toDate={toDate}
            setToDate={setToDate}
            load={csvLoading}
          />

          <div className="flex items-center justify-between mt-[30px]">
            <h2 className="text-black font-bold text-xl ">Transactions</h2>
            <button
              onClick={() => setExportPopupVisible(true)}
              className="bg-cyan-300 border border-[green] px-10 py-2 rounded-[10px] shadow-lg"
            >
              Export
            </button>
          </div>

          <div className="table-wrapper max-h-[38rem] overflow-auto">
            <table className="w-full">
              <tbody>
                {completedTrans?.map((transaction) => (
                  <TableRow key={transaction?.id} transaction={transaction} />
                ))}
                {!isLoading && completedTrans?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {completedTrans &&
              completedTrans?.length > 49 &&
              completedTrans?.length === toShow && (
                <div className="flex justify-center">
                  <button
                    className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                    onClick={() => setToShow(toShow + 50)}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RecenetTransactions;

function TableRow({ transaction }: { transaction: UserTransaction }) {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <tr
        className={` ${
          transaction?.type === "Withdraw"
            ? "bg-[#FFDEEA]"
            : transaction?.type === "Withdraw Fiat"
            ? "bg-white"
            : transaction?.type.includes("Swap")
            ? "bg-[#F3F3F3]"
            : "bg-cyan-300"
        }`}
      >
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs ml-3">
            {new Date(transaction?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            <b>
              {transaction?.type === "Withdraw Fiat"
                ? "Withdrawal to bank"
                : transaction?.type === "Withdraw"
                ? "Withdrawal"
                : transaction?.type.includes("Swap")
                ? "Naira Swap"
                : transaction?.type}
            </b>{" "}
            from {transaction?.user?.email}
          </p>
        </td>
        <td className="py-5 px-2 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center">
            {(transaction?.type.includes("Withdraw Fiat") ||
              transaction?.type.includes("Deposit Fiat")) &&
              "₦ "}
            {Number(transaction?.amount)?.toLocaleString()}{" "}
            {transaction?.coin?.symbol}
          </p>
        </td>
        <td className="rounded-r-lg px-2 border border-black-800/30 border-l-0 py-5">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`${
              transaction?.type === "Withdraw"
                ? "bg-[#FF689F] text-white"
                : transaction?.type === "Withdraw Fiat"
                ? "bg-black-800 text-white"
                : transaction?.type === "Swap"
                ? "bg-[#D4D4D4] text-black"
                : "bg-cyan-800 text-black"
            } text-xs font-semibold rounded-lg px-5 py-2`}
          >
            {isEdit ? "Collapse" : "See Details"}
          </button>
        </td>
      </tr>
      <tr
        className={`${
          transaction?.type === "Withdraw"
            ? "bg-[#FFDEEA] after:bg-[#FFDEEA]"
            : transaction?.type === "Withdraw Fiat"
            ? "bg-white after:bg-white"
            : transaction?.type.includes("Swap")
            ? "bg-[#F3F3F3] after:bg-[#F3F3F3]"
            : "bg-cyan-300 after:bg-cyan-300"
        } ${
          isEdit
            ? "relative after:absolute after:border-x after:border-black-800/30 after:left-0 after:bottom-[100%] after:block after:w-[100%] after:h-[18px]"
            : ""
        }
        
        `}
      >
        <td
          colSpan={6}
          className={`p-0 ${
            isEdit ? "border border-black-800/30" : ""
          } rounded-b-lg border-t-0`}
        >
          {isEdit &&
            (transaction?.type === "Withdraw Fiat" ||
            transaction?.type === "Deposit Fiat" ? (
              <table className="px-6 pb-6">
                <tr>
                  {/* <td>
                    <strong className="text-xs">Funds sent to</strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.accountNumber}
                    </span>
                    <span className="text-xs mt-3 block">
                      {transaction?.bankName}
                    </span>
                  </td> */}
                  <td valign="top">
                    <strong className="text-xs">User Remaining Balance</strong>
                    <span className="text-xs mt-3 block">
                      ₦{" "}
                      {new Intl.NumberFormat("en", {
                        maximumFractionDigits: 4,
                      }).format(transaction?.balance)}
                    </span>
                  </td>
                  <td valign="top">
                    <strong className="text-xs">User bank Details</strong>
                    <span className="text-xs mt-3 block">
                      Name: {transaction?.accountName}
                    </span>
                    <span className="text-xs mt-3 block">
                      Number: {transaction?.accountNumber}
                    </span>
                  </td>
                  <td valign="top">
                    <strong className="text-xs">Approved By:</strong>
                    <span className="text-xs mt-3 block">
                      Name: {transaction?.assignedAdmin?.fullname}
                    </span>
                    <span className="text-xs mt-3 block">
                      Email: {transaction?.assignedAdmin?.email}
                    </span>
                  </td>
                  <td valign="top">
                    <Link
                      to={`/user-dictionary?search=${transaction?.user?.email}`}
                      className="text-black-900 text-xs leading-relaxed font-semibold"
                    >
                      Check user in directory
                    </Link>
                  </td>
                </tr>
              </table>
            ) : (
              <table className="px-6 pb-6 w-full">
                <tr>
                  <td>
                    <strong className="text-xs">Transaction details</strong>
                    <strong className="text-xs block mt-3">
                      {transaction?.type === "Withdraw"
                        ? "Sent to wallet address:"
                        : "Incoming wallet address:"}
                    </strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.fromAddress}
                    </span>
                  </td>
                  <td valign="top">
                    <strong className="text-xs">User wallet address:</strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.toAddress}
                    </span>
                  </td>
                  {transaction?.type === "Swap" ? (
                    <td valign="top">
                      <strong className="text-xs">Swapped Amount</strong>
                      <span className="text-xs mt-3 block">
                        ₦ {Number(transaction?.swappedAmount)?.toLocaleString()}
                      </span>
                    </td>
                  ) : transaction?.type === "Withdraw" ? (
                    ""
                  ) : (
                    <td valign="top">
                      <strong className="text-xs">User balance</strong>
                      <span className="text-xs mt-3 block">
                        <img
                          src={transaction?.coin?.icon}
                          className="w-4 h-4 inline mr-1"
                        />{" "}
                        {Number(transaction?.balance)?.toLocaleString()}
                      </span>
                    </td>
                  )}
                  <td valign="top">
                    <span className="text-black-900 text-xs font-semibold mb-3 block">
                      Network: {transaction?.coin?.name}
                    </span>
                    <Link
                      to={`/user-dictionary?search=${transaction?.user?.email}`}
                      className="text-black-900 text-xs leading-relaxed font-semibold"
                    >
                      Check user in directory
                    </Link>
                  </td>
                </tr>
              </table>
            ))}
        </td>
      </tr>
    </>
  );
}
