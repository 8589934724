import Spinner from "components/Spinner";
import { getMessaging, onMessage } from "firebase/messaging";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { useNotificationHistory } from "utils/api/auth.api";

// import { type NotificationData } from "utils/types/user.type";

export default function Notifications() {
  const messaging = getMessaging();
  const [toShow, setToShow] = useState(50);
  const { data, isLoading, refetch } = useNotificationHistory(toShow);

  useEffect(() => {
    void refetch();
  }, [toShow]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      void refetch();
    });
    return unsubscribe;
  }, []);
  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-6xl p-6">
          <div className="flex justify-between items-center flex-wrap gap-3">
            <h2 className="text-black font-bold text-lg md:text-xl ">
              Notifications
            </h2>
            {/* <div className="flex w-[fit-content] gap-3">
              <button className="bg-cyan-300 border border-[green] px-3 py-2 rounded-[10px] shadow-lg block">
                Mark All as Read{" "}
                <i className="fa-regular fa-envelope-open ml-2 mt--2"></i>
              </button>
            </div> */}
          </div>
          <div className="table-wrapper max-h-[48rem] overflow-auto">
            <table className="w-full">
              <tbody>
                <tr className="px-2 bg-cyan-700 text-white  ">
                  <td className="rounded-l-lg  px-5 py-5">
                    <p className="font-bold text-xs">User Name</p>
                  </td>
                  <td className="py-5 px-2">
                    <p className="font-bold text-xs ">Type</p>
                  </td>
                  <td className="py-5 px-2">
                    <p className="font-bold text-xs ">Message</p>
                  </td>
                  <td className="py-5 px-2">
                    <p className="font-bold text-xs ">Description</p>
                  </td>

                  <td className="py-5 px-2 rounded-r-lg w-[100px]">
                    <p className="font-bold text-xs ">Time</p>
                  </td>
                </tr>
                {isLoading ? (
                  <Spinner />
                ) : data?.length === 0 ? (
                  <p>No data available</p>
                ) : (
                  data?.map((item) => <TableRow key={item?.id} data={item} />)
                )}
              </tbody>
            </table>
            {data && data?.length > 49 && data?.length === toShow && (
              <div className="flex justify-center">
                <button
                  className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
                  onClick={() => setToShow(toShow + 50)}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Load More"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ data }: { data: any }) {
  // const getTypeRoute = (type: any) => {
  //   switch (type) {
  //     case "swap":
  //     case "deposit":
  //     case "withdraw":
  //     case "Swap":
  //     case "Deposit":
  //     case "Withdraw":
  //       return "completed-transactions";
  //     case "DepositFiat":
  //     case "depositFiat":
  //     case "Deposit Fiat":
  //     case "deposit Fiat":
  //       return "pending-transactions";
  //     case "WithdrawFiat":
  //     case "withdrawFiat":
  //     case "Withdraw Fiat":
  //     case "withdraw Fiat":
  //       return "pending-transactions";
  //     case "airtime":
  //     case "Airtime":
  //       return "payments-history";
  //     case "internet":
  //     case "Internet":
  //       return "internet";
  //     case "electricity":
  //     case "Electricity":
  //       return "electricity";
  //     case "tv-subscription":
  //     case "TvSubscription":
  //     case "Tv-Subscription":
  //     case "tvSubscription":
  //       return "tv-subscription";
  //     case "Completed":
  //     case "completed":
  //       return "completed-transactions";
  //     case "Rejected":
  //     case "rejected":
  //       return "completed-transactions";
  //     case "Pending":
  //     case "pending":
  //       return "pending-transactions";
  //     case "kyc3Verification":
  //     case "Kyc3Verification":
  //       return "kyc";
  //     default:
  //       return "completed-transactions"; // Handle other cases if needed
  //   }
  // };

  // const navigate = useNavigate();
  return (
    <>
      <tr
        className={`px-2 ${
          data?.type !== "Logged Out" ? "bg-cyan-300" : "bg-[#FFDEEA]"
        }`}
      >
        <td className="rounded-l-lg  px-5 py-5">
          <p className="text-black-900 text-xs">{data?.user?.fullname}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.type}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.notificationTitle}</p>
        </td>

        <td className="py-5 px-2">
          <p className="text-black-900 text-xs">
            {data?.notificationBody.split(" ").slice(1).join(" ")}
          </p>
        </td>

        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          <p className="text-black-900 text-xs">
            {new Date(data?.createdAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
      </tr>
    </>
  );
}
