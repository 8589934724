import Spinner from "components/Spinner";
import ChakraUIMenu from "components/menu";
import TabNav from "components/tabNav";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useGetKyc3UsersVerifyRecords,
  useVerifyAndUpgradeKYC3ByAdmin,
} from "utils/api/user.api";
import { type AccountLevelData } from "utils/types/user.type";
import { accountUpgradeLinks } from "views/accountLevelUpgrade";

export default function KYC() {
  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading, refetch } =
    useGetKyc3UsersVerifyRecords(searchValue);
  const verifyAndUpgradeMutation = useVerifyAndUpgradeKYC3ByAdmin();
  console.log(data);

  useEffect(() => {
    const timeout = setTimeout(() => {
      void refetch();
    }, 1200);
    return () => clearTimeout(timeout);
  }, [searchValue]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <TabNav navLinks={accountUpgradeLinks} />

          <div className="overflow-auto mt-4">
            <div className="w-[976px] h-[85dvh]">
              <form action="#" className="flex items-center gap-6 mb-4">
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by date, email address or transaction type"
                  className="w-full text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-3.5 px-5"
                />
                <button className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3">
                  Search
                </button>
              </form>
              <div className="table-wrapper max-h-[48rem] overflow-auto">
                <table className="w-full">
                  <tbody>
                    <tr className="px-2 bg-cyan-700 text-white  ">
                      <td className="rounded-l-lg  px-5 py-5">
                        <p className="font-bold text-xs">Time</p>
                      </td>
                      <td className="py-5 px-2">
                        <p className="font-bold text-xs ">Name</p>
                      </td>
                      <td className="py-5 px-2">
                        <p className="font-bold text-xs ">Email</p>
                      </td>
                      <td className="py-5 px-2">
                        <p className="font-bold text-xs ">Level</p>
                      </td>

                      <td className="py-5 px-2 rounded-r-lg w-[100px]">
                        Action
                      </td>
                    </tr>
                    {isLoading ? (
                      <Spinner />
                    ) : data?.length === 0 ? (
                      "No Data Available"
                    ) : (
                      data?.map((data, idx) => (
                        <TableRow
                          key={data?.id}
                          data={data}
                          index={idx}
                          verifyAndUpgradeMutation={verifyAndUpgradeMutation}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({
  data,
  index,
  verifyAndUpgradeMutation,
}: {
  data: AccountLevelData;
  index: number;
  verifyAndUpgradeMutation: any;
}) {
  const [isVisible, setIsVisible] = useState(false); // Change initial state to `false`

  const handleClick = async (status: string) => {
    try {
      const formData = {
        UserId: data?.userId,
        kycTier: data?.kycTier,
        status: status === "Approve" ? "Approved" : "Rejected",
      };
      if (
        data?.adminApprovel === "Approved" ||
        data?.adminApprovel === "Rejected"
      ) {
        toast.error("Status already updated!!");
        return;
      }
      await verifyAndUpgradeMutation.mutateAsync(formData);

      toast.success("Status updated!!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <tr className={"bg-cyan-300"}>
        <td className="rounded-l-lg px-5 py-5">
          <p className="text-black-900 text-xs">
            {new Date(data?.updatedAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.name}</p>
        </td>
        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">{data?.email}</p>
        </td>

        <td className="py-5 px-2">
          <p className="text-black-900 text-xs ">Level {data?.kycTier}</p>
        </td>

        <td className="py-5 px-5 rounded-r-lg w-[100px]">
          <div className="flex gap-4">
            <i
              className="fa-regular fa-eye text-red hover:text-[19px]"
              style={{ cursor: "pointer", color: "" }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => setIsVisible(!isVisible)}
            ></i>
            <ChakraUIMenu
              options={["Approve", "Reject"]}
              onClick={handleClick}
            />
          </div>
        </td>
      </tr>
      {isVisible && (
        <tr className="bg-cyan-300 ">
          <td colSpan={5} className=" px-2 rounded-r-lg">
            <div className="px-3 pt-6" key={index}>
              <h2 className="text-lg font-bold mb-4">User Details</h2>
              <div className="flex items-center justify-between mb-5">
                <div className="flex gap-2 items-center">
                  <img
                    src="Portrait-Placeholder.jpg"
                    className="h-[30px] rounded-[20px]"
                  />
                  <p>{data.name}</p>
                </div>
                <p>{data.email}</p>
                <p>Level {data.kycTier}</p>
                <p>{data?.adminApprovel}</p>
              </div>
              {Number(data?.kycTier) === 3 && (
                <h2 className="text-lg font-bold mb-4">Source of Fund</h2>
              )}
              {Number(data?.kycTier) === 2 ? (
                <div className="flex gap-5 flex-wrap items-center">
                  <p>
                    <strong>Means of ID:</strong> {data?.source?.means_of_id}
                  </p>
                  <p>
                    <strong>Document Number:</strong>{" "}
                    {data?.source?.documentNumber}
                  </p>
                  <p>
                    <strong>Phone Number:</strong> {data?.source?.phoneNumber}
                  </p>
                  <p>
                    <strong>Gender:</strong> {data?.source?.gender}
                  </p>
                  <p>
                    <strong>Date of Birth:</strong> {data?.source?.dob}
                  </p>
                  <p>
                    <strong>Place of Birth:</strong>{" "}
                    {data?.source?.placeOfBirth}
                  </p>
                  <p>
                    <strong>Street:</strong> {data?.source?.street}
                  </p>
                  <p>
                    <strong>City:</strong> {data?.source?.city}
                  </p>
                  <p>
                    <strong>State:</strong> {data?.source?.state}
                  </p>
                  <p>
                    <strong>Country:</strong> {data?.source?.Country}
                  </p>
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <p>{data?.occupation}</p>

                  <p>{data?.description}</p>
                </div>
              )}
              {Number(data?.kycTier) === 2 && (
                <h2 className="text-lg font-bold my-4">Documents</h2>
              )}

              {Number(data?.kycTier) === 2 ? (
                <div className="flex flex-col">
                  <div
                    className="flex justify-center"
                    style={{
                      maxHeight: "450px",
                      maxWidth: "450px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <img
                      src={
                        data?.source?.imageOfId &&
                        data?.source?.imageOfId === ""
                          ? "Placeholder.png"
                          : data?.source?.imageOfId
                      }
                      className="my-2 cursor-pointer rounded-xl"
                      alt="document"
                      onClick={() => window.open(data?.source?.imageOfId)}
                    />
                  </div>
                  <div
                    className="flex justify-center"
                    style={{
                      maxHeight: "450px",
                      maxWidth: "450px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <img
                      src={
                        data?.source?.passportPhoto &&
                        data?.source?.passportPhoto === ""
                          ? "Placeholder.png"
                          : data?.source?.passportPhoto
                      }
                      className="my-2 cursor-pointer rounded-xl"
                      alt="document"
                      onClick={() => window.open(data?.source?.passportPhoto)}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center"
                  style={{
                    maxHeight: "450px",
                    maxWidth: "450px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <img
                    src={
                      data?.sourceOfFund && data?.sourceOfFund === ""
                        ? "Placeholder.png"
                        : data?.sourceOfFund
                    }
                    className="my-2 rounded-xl cursor-pointer"
                    alt="document"
                    onClick={() => window.open(data?.sourceOfFund)}
                  />
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
