import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PendingTransactions from "views/pendingTransactions";
import CompletedTransactions from "views/completedTransactions";
import AdminAccess from "views/adminAccess";
import ChangeEmailAddress from "views/changeEmail";
import ChangePassword from "views/changePassword";
import UserDictionary from "views/userDictionary";
import RateSettings from "views/rateSettings";
import Login from "views/login";
import Signup from "views/signup";
import ForgotPassword from "views/forgotPasswoard";
import VerifyEmail from "views/verifyEmail";
import ResetPassword from "views/resetPassword";
import Logout from "views/logout";
import Home from "views/home";
import SystemStatus from "components/systemStatus";
import UserActivity from "components/userActivity";
import RecenetTransactions from "components/recentTransactions";
import DeviceHistory from "views/deviceHistory";
import PaymentsHistory from "views/paymentsHistory";
import AccountLevelUpgrade from "views/accountLevelUpgrade";
import KYC from "components/kyc";
import Notifications from "views/notifications";
import TwoFactorSetupPage from "views/2fa";
import Analytics from "views/analytics";
import TVSubscription from "components/tvSubscription";
import Internet from "components/internet";
import Electricity from "components/electricity";
import ReferralHistory from "views/referralHistory";
import KYCHistory from "components/kycHistory";
import SellRate from "views/sellRate";
import BankDetails from "views/bankDetails";

const routes = [
  {
    path: "/",
    Component: Home,
    isPrivate: true,
  },
  {
    path: "/system-status",
    Component: SystemStatus,
    isPrivate: true,
  },
  {
    path: "/user-activity",
    Component: UserActivity,
    isPrivate: true,
  },
  {
    path: "/recent-transactions",
    Component: RecenetTransactions,
    isPrivate: true,
  },
  {
    path: "/pending-transactions",
    Component: PendingTransactions,
    isPrivate: true,
  },
  {
    path: "/completed-transactions",
    Component: CompletedTransactions,
    isPrivate: true,
  },
  {
    path: "/user-dictionary",
    Component: UserDictionary,
    isPrivate: true,
  },
  {
    path: "/device-history",
    Component: DeviceHistory,
    isPrivate: true,
  },
  {
    path: "/payments-history",
    Component: PaymentsHistory,
    isPrivate: true,
  },
  {
    path: "/referral-history",
    Component: ReferralHistory,
    isPrivate: true,
  },

  {
    path: "/internet",
    Component: Internet,
    isPrivate: true,
  },
  {
    path: "/electricity",
    Component: Electricity,
    isPrivate: true,
  },
  {
    path: "/tv-subscription",
    Component: TVSubscription,
    isPrivate: true,
  },
  {
    path: "/account-level-upgrade",
    Component: AccountLevelUpgrade,
    isPrivate: true,
  },
  {
    path: "/kyc",
    Component: KYC,
    isPrivate: true,
  },
  {
    path: "/kyc-history",
    Component: KYCHistory,
    isPrivate: true,
  },
  {
    path: "/notifications",
    Component: Notifications,
    isPrivate: true,
  },
  {
    path: "/admin-access",
    Component: AdminAccess,
    isPrivate: true,
  },
  {
    path: "/2fa",
    Component: TwoFactorSetupPage,
    isPrivate: true,
  },
  {
    path: "/analytics",
    Component: Analytics,
    isPrivate: true,
  },
  {
    path: "/change-email",
    Component: ChangeEmailAddress,
    isPrivate: true,
  },
  {
    path: "/change-password",
    Component: ChangePassword,
    isPrivate: true,
  },
  {
    path: "/bank-details",
    Component: BankDetails,
    isPrivate: true,
  },
  {
    path: "/rate-settings",
    Component: RateSettings,
    isPrivate: true,
  },
  {
    path: "/sell-rate",
    Component: SellRate,
    isPrivate: true,
  },
  {
    path: "/login",
    Component: Login,
    isPrivate: false,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/verify-email",
    Component: VerifyEmail,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/logout",
    Component: Logout,
    isPrivate: false,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component, isPrivate }) => (
        <Route
          key={path}
          element={
            isPrivate ? (
              <PrivateRoute component={<Component />} />
            ) : (
              <Component />
            )
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/pending-transactions" />} />
    </Routes>
  );
}
